import { useLocation } from "react-router-dom";
import { Breadcrumb, Typography } from "antd";
import styles from "./header.module.scss";

const { Text } = Typography;

interface IPageHeader {
  pageTitle: string;
}

const PageHeader = ({ pageTitle }: IPageHeader) => {
  const location = useLocation();
  const pathSnippets = location.pathname.split("/").filter((i) => i);

  const breadcrumbItems = () => {
    const items = [];

    if (pathSnippets && pathSnippets.length > 0) {
      items.push({
        title: <a href="/" className={styles.breadcrumb_item}>Home</a>,
      });
      for (let i = 0; i < pathSnippets.length; i++) {
        const url = `/${pathSnippets.slice(0, i + 1).join("/")}`;
        let breadcrumbText = pathSnippets[i].replace(/-/g, " ");
        if (breadcrumbText && breadcrumbText.length > 0) {
          breadcrumbText = breadcrumbText.charAt(0).toUpperCase() + breadcrumbText.slice(1);
        }
        items.push({
          title: (
            <a
              href={url}
              className={
                i === pathSnippets.length - 1 ? styles.breadcrumb_current : styles.breadcrumb_item
              }
            >
              {breadcrumbText?.replace(/%20/g, " ")}
            </a>
          ),
        });
      }
    }

    return items;
  };

  return (
    <div className={styles.page_header}>
      <Breadcrumb
        items={breadcrumbItems()}
        className={styles.breadcrumbs_wrapper}
      />
      <Text className={styles.title}>{pageTitle}</Text>
    </div>
  );
};

export default PageHeader;
