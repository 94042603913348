import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form, Input, Select, Typography } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { SelectProps } from "rc-select/lib/Select";
import { allTimezones, useTimezoneSelect } from "react-timezone-select";
import useGeoLocation from "src/hooks/useGeoLocation";
import { CAPOSTALCODEREGEX, provinces, selectFilterOption, states, USPOSTALCODEREGEX } from "src/constants/common";
import { labelStyle } from "src/constants/samples";
import { getOptionsList } from "src/utils/helper-functions";
import styles from "src/pages/subscribe/subscribe.module.scss";

const { Text } = Typography;

interface IEditCompanyBlock {
  form: any;
  selectedCountry?: string;
  setSelectedCountry: (selectedCountry: string) => void;
}

const EditCompanyBlock = ({
  form,
  selectedCountry,
  setSelectedCountry,
}: IEditCompanyBlock) => {
  const { t } = useTranslation();
  const location = useGeoLocation();

  const { options: timezoneOptions, parseTimezone } = useTimezoneSelect({
    labelStyle,
    timezones: allTimezones,
  });

  const requiredFields = {
    companyName: true,
    billingEmailAddress: true,
    timezone: true,
    howDidYouHear: false,
    country: true,
    province: true,
    state: true,
    city: true,
    postalCode: true,
  };

  const timezoneList = () => {
    const list = [];

    for (let i = 0; i < timezoneOptions.length; i++) {
      list.push({
        key: `timezone-${i}`,
        label: timezoneOptions[i].label,
        value: timezoneOptions[i].value
      });
    }

    return list;
  };

  const timeZoneOptions: SelectProps['options'] = timezoneList();
  const countryOptions: SelectProps['options'] = [
    { key: "CA", label: t("geoLocation.countries.CA"), value: "CA" },
    { key: "US",label: t("geoLocation.countries.US"), value: "US" },
  ];
  const provinceOptions: SelectProps['options'] = getOptionsList(provinces);
  const stateOptions: SelectProps['options'] = getOptionsList(states);

  useEffect(() => {
    if (
      !selectedCountry
      && location?.data?.countryCode
      && countryOptions.find(o => o.value === location.data.countryCode)
    ) {
      setSelectedCountry(location.data.countryCode);
    }
  }, [location]);

  const reviewInputBlock = (
    label: string,
    rules: any[],
    required: boolean,
    name: string,
    placeholder: string = "",
    disabled: boolean = false,
  ) => {
    return (
      <div className={styles.review_block}>
        <Text className={styles.review_block_label}>
          {label}
        </Text>
        <Form.Item
          name={name}
          rules={rules}
          className={
            `${styles.sign_up_form_item} ${
              required ? styles.required : ""
            }`
          }
        >
          <Input
            type="text"
            placeholder={placeholder}
            className={styles.sign_up_form_input}
            disabled={disabled}
          />
        </Form.Item>
      </div>
    );
  };

  const reviewSelectBlock = (
    label: string,
    rules: any[],
    required: boolean,
    name: string,
    placeholder: string = "",
    options: DefaultOptionType[] | undefined,
    showSearch: boolean = true,
    selectValue: string | undefined = undefined,
    onChangeValue: any = undefined,
  ) => {
    return (
      <div className={styles.review_block}>
        <Text className={styles.review_block_label}>
          {label}
        </Text>
        <Form.Item
          name={name}
          rules={rules}
          className={
            `${styles.sign_up_form_item} ${
              required ? styles.required : ""
            }`
          }
        >
          {selectValue !== undefined && onChangeValue !== undefined ? (
            <Select
              showSearch={showSearch}
              placeholder={placeholder}
              options={options}
              value={selectValue}
              onChange={onChangeValue}
              filterOption={showSearch ? selectFilterOption : undefined}
              className={`${styles.sign_up_form_input} ${styles.sign_up_form_select}`}
            />
          ) : (
            <Select
              showSearch={showSearch}
              placeholder={placeholder}
              options={options}
              filterOption={showSearch ? selectFilterOption : undefined}
              className={`${styles.sign_up_form_input} ${styles.sign_up_form_select}`}
            />
          )}
        </Form.Item>
      </div>
    );
  };

  const onChangeCountry = (value: string) => {
    setSelectedCountry(value);
    if (value === "CA") {
      form.resetFields(["state"]);
    } else if (value === "US") {
      form.resetFields(["province"]);
    }
  };

  const validatePostalCode = () => {
    const postalCode = form.getFieldValue("postalCode");
    const trimmedValue = postalCode ? postalCode.trim() : "";

    if (!trimmedValue || trimmedValue.length === 0) {
      return Promise.resolve();
    }

    if (selectedCountry === "CA") {
      if (!CAPOSTALCODEREGEX.test(trimmedValue)) {
        return Promise.reject(new Error(t("fields.postalCodeInvalid")));
      }
    }

    if (selectedCountry === "US") {
      if (!USPOSTALCODEREGEX.test(trimmedValue)) {
        return Promise.reject(new Error(t("fields.zipCodeInvalid")));
      }
    }

    return Promise.resolve();
  };

  return (
    <div
      className={
        `${styles.review_section_sub} ${styles.review_section_sub_edit}`
      }
    >
      {reviewInputBlock(
        t("fields.companyName"),
        [
          {
            required: requiredFields.companyName,
            message: t("fields.companyNameRequired")
          }
        ],
        requiredFields.companyName,
        `companyName`,
        t("fields.companyName")
      )}
      {reviewInputBlock(
        t("fields.billingEmailAddress"),
        [
          {
            required: requiredFields.billingEmailAddress,
            message: t("fields.billingEmailRequired")
          },
          { type: "email", message: t("fields.emailError") },
        ],
        requiredFields.billingEmailAddress,
        `billingEmailAddress`,
        t("fields.billingEmailAddress")
      )}
      {reviewSelectBlock(
        t("fields.chooseTimezone"),
        [
          {
            required: requiredFields.timezone,
            message: t("fields.chooseTimezoneRequired")
          }
        ],
        requiredFields.timezone,
        `timezone`,
        t("fields.chooseTimezone"),
        timeZoneOptions
      )}
      {reviewSelectBlock(
        t("fields.selectCountry"),
        [
          {
            required: requiredFields.country,
            message: t("fields.selectCountryRequired")
          }
        ],
        requiredFields.country,
        `country`,
        t("fields.selectCountry"),
        countryOptions,
        false,
        selectedCountry,
        onChangeCountry
      )}
      {selectedCountry === "CA" ? (
        reviewSelectBlock(
          t("fields.selectProvince"),
          [
            {
              required: requiredFields.province,
              message: t("fields.selectProvinceRequired")
            }
          ],
          requiredFields.province,
          `province`,
          t("fields.selectProvince"),
          provinceOptions
        )
      ) : (
        reviewSelectBlock(
          t("fields.selectState"),
          [
            {
              required: requiredFields.state,
              message: t("fields.selectStateRequired")
            }
          ],
          requiredFields.state,
          `state`,
          t("fields.selectState"),
          stateOptions
        )
      )}
      {reviewInputBlock(
        t("fields.selectCity"),
        [
          {
            required: requiredFields.city,
            message: t("fields.selectCityRequired")
          }
        ],
        requiredFields.city,
        `city`,
        t("fields.selectCity")
      )}
      {reviewInputBlock(
        selectedCountry === "CA"
          ? t("fields.postalCode")
          : t("fields.zipCode"),
        [
          {
            required: requiredFields.postalCode,
            message: selectedCountry === "CA"
              ? t("fields.postalCodeRequired")
              : t("fields.zipCodeRequired")
          },
          { validator: validatePostalCode },
        ],
        requiredFields.postalCode,
        `postalCode`,
        selectedCountry === "CA"
          ? t("fields.postalCode")
          : t("fields.zipCode")
      )}
      {reviewInputBlock(
        t("fields.howDidYouHear"),
        [],
        requiredFields.howDidYouHear,
        `howDidYouHear`,
        t("fields.howDidYouHear")
      )}
    </div>
  );
};

export default EditCompanyBlock;
