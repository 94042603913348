import { Button, Col, Typography } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getPlanDetails } from "src/constants/subscription";
import styles from "src/pages/subscribe/subscribe.module.scss";

const { Text } = Typography;

interface IPlanInfo {
  planData: any;
  onSelectPlan?: (plan: any) => void;
  onContact?: () => void;
}

const PlanDetail = ({ planData, onSelectPlan, onContact }: IPlanInfo) => {
  const { t } = useTranslation();

  const [isLoadingPlan, setIsLoadingPlan] = useState<boolean>(true);
  const [planDetails, setPlanDetails] = useState<any>(null);

  const planType = !planData?.monthlyRate
    ? "more"
    : planData.monthlyRate === "0"
    ? "free"
    : "other";

  useEffect(() => {    
    if (!isLoadingPlan) return;

    if (planData) {
      setIsLoadingPlan(false);
      setPlanDetails(getPlanDetails(planData.messageThreshold));
    }
  }, [planData]);

  return (
    <Col
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
      }}
      className={styles.plan_card}
    >
      <div className={styles.card_top}>
        <div className={styles.top_part}>
          <Text className="font-size-24 font-weight-800">
            {planData.stripeProductName}
          </Text>
          {planDetails?.description && (
            <Text>
              {t("subscribeStep0.forBusinesses")} <span dangerouslySetInnerHTML={{ __html: planDetails.description }} />
            </Text>
          )}
        </div>
        <div className={styles.top_part}>
          <div className={styles.price}>
            {planType === "more" ? (
              <Text
                style={{ lineHeight: "1.2" }}
                className="font-size-22 font-weight-600"
              >
                {t("subscribeStep0.talkToUs")}
              </Text>
            ) : (
              <Text className="font-size-24 font-weight-600">
                {planType === "free"
                  ? t("subscribe.alwaysFree")
                  : (
                    <>
                      ${planData.monthlyRate}
                      <span style={{ marginLeft: "0.3rem" }} className="font-size-16 font-weight-500">
                        {t("common.CAD")}/{t("common.month")}
                      </span>
                    </>
                  )
                }
              </Text>
            )}
            {planType === "other" && (
              <>
                <Text className="font-size-14 font-weight-400">
                  {t("subscribe.additionalUsers")}: {t("subscribe.fiveDollarMonthEach")}
                </Text>
                <Text className="font-size-14 font-weight-400">
                  {t("subscribe.additionalNumbers")}: {t("subscribe.fiveDollarMonthEach")}
                </Text>
                {planData?.messageRate && (
                  <Text className="font-size-14 font-weight-400">
                    {t("subscribeStep0.overage")}: {planData.messageRate} {t("subscribe.centsPerUnit")}
                  </Text>
                )}
              </>
            )}
          </div>
          <Button
            style={{ marginTop: "1rem" }}
            className={styles.secondary}
            onClick={() => {
              if (onSelectPlan) onSelectPlan({ ...planData, ...planDetails });
              else if (onContact) onContact();
            }}
          >
            {planType === "more"
              ? t("subscribe.contact")
              : planType === "free"
              ? t("subscribeStep0.getStarted")
              : t("subscribeStep0.getStartedForFree")
            }
          </Button>
        </div>
      </div>
      <div className={styles.card_bottom}>
        <Text
          style={{ marginBottom: "0.5rem", color: "#56575A" }}
          className="font-size-14 font-weight-600"
        >
          {planType === "free"
            ? t("subscribeStep0.freeFeatures")
            : t("subscribeStep0.freeFeaturesPlus")
          }
        </Text>
        {planDetails?.features
          && planDetails.features.length > 0
          && planDetails.features.map((feature: string, index: number) => (
            <div
              key={`${planData.id}-feature-${index}`}
              style={{ display: "flex", alignItems: "start", marginBottom: "0.3rem" }}
            >
              <CheckCircleOutlined
                className={`${styles.feature} font-size-20`}
              />
              <Text>
                <span
                  style={{ color: "#56575A" }}
                  dangerouslySetInnerHTML={{ __html: feature }}
                />
              </Text>
            </div>
          )
        )}
      </div>
    </Col>
  );
};

export default PlanDetail;
