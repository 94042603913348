import { useEffect, useState } from "react";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { FILE_MAX_SIZE } from "src/constants/common";
import RequestAppAction from "src/store/slices/appActions";

const useFileUpload = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // const isUploadingToServer = useSelector(uploadFilesLoading);

  const [files, setFiles] = useState<any>(null);
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);
  const [success, setSuccess] = useState<any>(null);

  useEffect(() => {
    if (isFileUploading) {
      return;
    }

    if (files && files.length > 0) {
      fileUpload();
    }
  }, [files]);

  const isValidFile = (fileName: string, fileSize: number) => {
    if (!fileName || fileName.length === 0) {
      return false;
    }

    fileName = fileName.toLowerCase();

    // File extension restriction
    if (
      !fileName.endsWith(".png") &&
      !fileName.endsWith(".jpg") &&
      !fileName.endsWith(".jpeg") &&
      !fileName.endsWith(".jpe") &&
      !fileName.endsWith(".jif") &&
      !fileName.endsWith(".jfif") &&
      !fileName.endsWith(".jfi") &&
      !fileName.endsWith(".gif") &&
      !fileName.endsWith(".svg") &&
      !fileName.endsWith(".svgz") &&
      !fileName.endsWith(".webp")
    ) {
      notification.error({
        message: t("common.fileNotSupported")
      });

      return false;
    }

    // File size restriction
    if (fileSize > FILE_MAX_SIZE) {
      notification.error({
        message: t("common.fileSizeError", { name: fileName }),
      });

      return false;
    }

    return true;
  };

  const finishUpload = (successData: any = null) => {
    setFiles(null);
    setIsFileUploading(false);
    setSuccess(successData || null);
  };

  const fileUpload = () => {
    if (!files || files.length === 0) {
      return;
    }

    setIsFileUploading(true);

    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      if (
        !(file instanceof File)
        || !isValidFile(file.name, file.size)
      ) {
        return finishUpload();
      }
    }

    for (const file of files) {
      formData.append("files", file);
    }

    dispatch(
      RequestAppAction.uploadFiles({
        data: formData,
        cbSuccess: (res: any) => {
          finishUpload(res);
        },
        cbFailure: (e: string) => {
          notification.error({
            message: e ?? t("notification.uploadFileFail")
          });
          finishUpload();
        },
      })
    );
  };

  return { files, setFiles, isFileUploading, success };
};

export default useFileUpload;
