import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { STATUS } from "src/constants/status";
import { StatusType } from "src/constants/types";

interface CurrentDidState {
  id: number | null;
  status: StatusType
}

const initialState: CurrentDidState = {
  id: null,
  status: STATUS.INACTIVE,
};

const currentDidSlice = createSlice({
  name: "currentDid",
  initialState,
  reducers: {
    setId: (state, action: PayloadAction<number>) => {
      state.id = action.payload;
    },
    setStatus: (state, action: PayloadAction<StatusType>) => {
      state.status = action.payload;
    },
    clearCurrentDid: (state) => {
      state.id = null;
    },
  },
});

export const { setId, setStatus, clearCurrentDid } = currentDidSlice.actions;
export const currentDidReducer = currentDidSlice.reducer;
