/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  CloseCircleFilled,
  DeleteOutlined,
  EditOutlined,
  MailOutlined,
  MessageOutlined,
  PaperClipOutlined,
  PhoneOutlined,
  PlusCircleOutlined,
  ProfileOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button as Btn,
  Card,
  Checkbox,
  Col,
  // DatePicker,
  Divider,
  Dropdown,
  Flex,
  Form,
  Input,
  Layout,
  Menu,
  MenuProps,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Tag,
  TimePicker,
  Tooltip,
  Typography,
  notification,
} from "antd";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LocalStorageService } from "src/services/local-storage";
import ContactErrorModal from "src/components/ContactErrorModal";
import { NewContactModal } from "src/components/NewContactModal";
import { Note } from "src/components/Note";
import AttachContacts from "src/components/attachContacts";
import BulkMessageModal from "src/components/bulk-message-modal/BulkMesssageModal";
import Button from "src/components/button";
import ListingSidebarContacts from "src/components/listing-contacts";
import { TextifyModal } from "src/components/modal";
import { PAGINATION, PhoneNumberRegex } from "src/constants/common";
import { formattedDateTime, returnElipsesText } from "src/constants/functions";
import { STATUS } from "src/constants/status";
import useFileUpload from "src/hooks/useFileUpload";
import { getContactUser } from "src/store/selectors/features/contactUser";
import { getCurrentDidId, getCurrentDidStatus } from "src/store/selectors/features/currentDid";
import { getEventsData } from "src/store/selectors/features/events";
import { getNotesData } from "src/store/selectors/features/notes";
import { getPostNoteLoading } from "src/store/selectors/features/postNote";
import { getTemplateData } from "src/store/selectors/features/template";
import { getUser, getUserLoading } from "src/store/selectors/features/user";
import RequestAppAction from "src/store/slices/appActions";
import { setContactUser } from "src/store/slices/features/currentUserContact";
import { setAllConversationsById } from "src/store/slices/features/allConversations";
import { getLoggedInUserData } from "src/store/selectors/features/loggedInUser";
import { getPreferenceData } from "src/store/selectors/features/preference";
import Colors from "src/themes/colors";
import useWindowDimensions from "src/hooks/useWindowDimensions";
import "./contact.scss";

const localStorageService = new LocalStorageService();

const { Sider, Content } = Layout;
const { Title, Text } = Typography;

interface modalProps {
  openModal: (text?: string) => void;
  closeModal: () => void;
}

const options = [
  {
    value: 1,
    label: "Anually",
  },
  {
    value: 2,
    label: "Monthly",
  },
];

const Contacts = () => {
  const { width } = useWindowDimensions();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = useForm();
  const { setFiles, isFileUploading, success } = useFileUpload();

  const userDetails: any = useSelector(getUser);
  const selectedUser = useSelector(getContactUser);
  const user: any = useSelector(getContactUser);
  const isLoading = useSelector(getUserLoading);
  const notes: any = useSelector(getNotesData);
  const events: any = useSelector(getEventsData);
  const handleNotesLoading = useSelector(getPostNoteLoading);
  const did = useSelector(getCurrentDidId);
  const didStatus = useSelector(getCurrentDidStatus);
  const templates: any = useSelector(getTemplateData);
  const currentUserData: any = useSelector(getLoggedInUserData);
  const preferencesData = useSelector(getPreferenceData);

  const uploadFileRef: any = useRef<HTMLInputElement | null>(null);
  const editContactModalRef = useRef<modalProps>(null);
  const scrollRef: any = useRef(null);
  const attachContacts = useRef<modalProps>(null);
  const modalRef = useRef<modalProps>(null);
  const addNewContactRef = useRef<modalProps>(null);
  const ErrorRef = useRef<modalProps>(null);

  const [_, contextHolderDel] = Modal.useModal();
  const [__, contextHolderEdit] = Modal.useModal();

  const [scrollFetch, setScrollFetch] = useState(false);
  const [pagination, setPagination] = useState({ ...PAGINATION });
  const [items, setItems] = useState<any>();
  const [eventItems, setEventItems] = useState<any>();
  const [editId, setEditId] = useState<null | number>(null);
  const [state, setState] = useState("");
  const [units, setUnits] = useState<number>(0);
  const [characters, setCharacters] = useState<number>(0);
  const [totalCharacters, setTotalCharacters] = useState<String>("");
  const [attachments, setAttachments] = useState<any>([]);
  const [editValue, setEditValue] = useState<any>(null);
  const [isBulkModalVisible, setIsBulkModalVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [frequency, setFrequency] = useState(1);
  const [errorData, setErrorData] = useState<any>([]);
  const [selectedMonth, setSelectedMonth] = useState(1);
  const [days, setDays] = useState<{ value: number; label: string }[]>([]);
  const [isEventEdit, setIsEventEdit] = useState(false);
  const [preferences, setPreferences] = useState<any>(null);

  const isPending = currentUserData?.isPending || didStatus !== STATUS.ACTIVE;

  const months = Array.from({ length: 12 }, (_, i) => {
    const date = new Date();
    date.setMonth(i);
    return {
      value: i + 1,
      label: date.toLocaleString("default", { month: "long" }),
    };
  });

  const openBulkModal = () => {
    setIsBulkModalVisible(true);
  };

  const handleMonthChange = (value: number) => {
    setSelectedMonth(value);
    const daysInMonth = new Date(new Date().getFullYear(), value, 0).getDate();
    const newDays = Array.from({ length: daysInMonth }, (_, i) => {
      const day = i + 1;
      return { value: day, label: day.toString() };
    });
    setDays(newDays);
  };

  const handleTextChange = async (e: any) => {
    setTotalCharacters(e.target.value);
  };

  useEffect(() => {
    if (!isModalVisible) {
      setFrequency(1);
    }
  }, [isModalVisible]);

  const eventInitialValues = { frequency: 1, eventId: null };
  const openModal = () => {
    if (user) {
      setIsModalVisible(true);
    }
  };
  const handleClose = () => {
    setIsModalVisible(false);
    form.resetFields();
    setIsEventEdit(false);
    setAttachments([]);
    setCharacters(0);
    setUnits(0);
  };
  const openContactModal = () => {
    addNewContactRef?.current?.openModal();
  };

  useEffect(() => {
    localStorageService.fetch("preferences").then((res) => {
      let data = "";
      if (res) {
        data = JSON.parse(res);
      }

      if (preferencesData || data) {
        setPreferences(preferencesData ?? data);

        if (!data) {
          localStorageService.persist(
            "preferences",
            JSON.stringify(preferencesData)
          );
        }
      } else {
        dispatch(
          RequestAppAction.fetchPreference({
            cbSuccess: (res: any) => {
              if (res?.data) {
                setPreferences(res.data);
              }
            },
            cbFailure: (e: string) => {
              notification.error({
                message: e ?? t("notification.getPreferenceFail")
              });
            },
          })
        );
      }
    });
  }, []);

  useEffect(() => {
    const currentDate = new Date();
    const daysInMonth = new Date(
      currentDate.getFullYear(),
      selectedMonth,
      0
    ).getDate();
    const initialDays = Array.from({ length: daysInMonth }, (_, i) => {
      const day = i + 1;
      return { value: day, label: day.toString() };
    });
    setDays(initialDays);
  }, [selectedMonth]);

  useEffect(() => {
    const charactersLength = totalCharacters.length;
    const totalUnits = Math.floor(charactersLength / 160);
    const chr = charactersLength - (totalUnits || 0) * 160;

    if (charactersLength === 0 && attachments?.length === 0) {
      setUnits(0);
    } else if (charactersLength > 0) {
      let val = totalUnits + 1 + attachments?.length * 3;
      setUnits(val);
    } else {
      let val = attachments?.length * 3;
      setUnits(val);
    }

    setCharacters(chr);
  }, [totalCharacters, attachments]);
  const add = () => {
    form.resetFields();

    setTimeout(() => {
      if (user) {
        modalRef.current?.openModal("New Contact Note");
      }
    }, 100);
  };
  const onEditNote = (val: any) => {
    modalRef.current?.openModal("Edit Contact Note");
    setTimeout(() => {
      form.setFieldsValue({
        note: val?.content,
      });
    }, 100);
  };

  const onDelete = (val: any) => {
    Modal.confirm({
      title: t("button.delete"),
      content: t("modal.confirmDelete"),
      okType: "danger",
      icon: null,
      centered: true,
      className: "p-0",
      okButtonProps: {
        style: { backgroundColor: Colors.red, color: Colors.white },
      },
      style: { borderRadius: 10 },
      width: "30%",

      onOk: () => {
        dispatch(
          RequestAppAction.deleteNote({
            id: val,
            cbSuccess: (data: any) => {
              onDeleteSuccess(data);
              modalRef.current?.closeModal();
              form.resetFields();
            },
          })
        );
      },
    });
  };

  useEffect(() => {
    if (notes && !scrollFetch) {
      setItems(notes);
    }
  }, [notes]);

  useEffect(() => {
    if (events) {
      setEventItems(events);
    }
  }, [events]);

  const onScroll = () => {
    if (scrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      const isNearBottom = scrollTop + clientHeight >= scrollHeight - 10;

      if (isNearBottom) {
        if (pagination.page <= items.meta?.totalPages) {
          setScrollFetch(true);
          dispatch(
            RequestAppAction.fetchNotes({
              id: userDetails?.id,
              meta: pagination,
              cbSuccess: (data: any) => {
                setItems((pre: any) => ({
                  ...pre,
                  items: [...items.items, ...data?.data?.items],
                }));
                setScrollFetch(false);
                setPagination((pre: any) => ({
                  ...pre,
                  page: pagination.page + 1,
                }));
              },
              cbFailure: (e: string) => {
                notification.error({
                  message: e ?? t("notification.fetchNotesFail"),
                });
                setScrollFetch(false);
              },
            })
          );
        }
      }
    }
  };

  const addFromResponse = (res: any) => {
    if (items?.items?.length <= 10) {
      fetchNewData();
    } else {
      setItems((pre: any) => ({
        ...pre,
        items: [res?.data, ...pre.items],
      }));
    }
  };
  const onEditSuccess = (res: {
    data: {
      id: string;
      updatedAt: string;
      content: string;
      createdAt: string;
    };
  }) => {
    if (items?.items?.length <= 10) {
      fetchNewData();
    } else {
      setItems((pre: any) => ({
        ...pre,
        items: [
          ...pre?.items?.map((i: any) => {
            if (i.id === res?.data?.id) {
              return {
                ...i,
                updatedAt: res?.data?.updatedAt,
                content: res?.data?.content,
                createdAt: res?.data?.createdAt,
              };
            } else {
              return { ...i };
            }
          }),
        ],
      }));
    }
  };
  const onDeleteSuccess = (res: {
    data: {
      id: string;
      updatedAt: string;
      content: string;
      createdAt: string;
    };
  }) => {
    if (items?.items?.length <= 10) {
      fetchNewData();
    } else {
      setItems((pre: any) => ({
        ...pre,
        items: [
          ...pre.items?.filter((i: { id: string }) => i.id !== res.data.id),
        ],
      }));
    }
  };

  useEffect(() => {
    const listInnerElement: any = scrollRef?.current;

    if (listInnerElement) {
      listInnerElement.addEventListener("scroll", onScroll);

      return () => {
        listInnerElement.removeEventListener("scroll", onScroll);
      };
    }
  }, [items, pagination.page]);

  const fetchNewData = () => {
    dispatch(
      RequestAppAction.fetchNotes({
        id: userDetails?.id,
        meta: PAGINATION,
        cbSuccess: (data: any) => {
          setItems(data?.data?.items ?? []);
          setPagination((pre: any) => ({
            ...pre,
            page: PAGINATION.page + 1,
          }));
        },
        cbFailure: (e: string) => {
          notification.error({
            message: e ?? t("notification.fetchNotesFail"),
          });
        },
      })
    );
  };

  const fetchNewEvents = () => {
    dispatch(
      RequestAppAction.fetchEvent({
        id: userDetails?.id,
        meta: PAGINATION,
        cbSuccess: (data: any) => {
          setEventItems(data?.data ?? []);
          setPagination((pre: any) => ({
            ...pre,
            page: PAGINATION.page + 1,
          }));
        },
        cbFailure: (e: string) => {
          notification.error({
            message: e ?? t("notification.getEventFail")
          });
        },
      })
    );
  };

  // const addContact = (i: any) => {
  //   modalAdd.confirm({
  //     centered: true,
  //     title: `new scheduled message for ${i?.firstName || i?.phoneNumber}`,
  //     zIndex: 10000,
  //     content: (
  //       <div className="p-5" style={{ zIndex: 10 }}>
  //         <div className="d-flex justify-content-between align-items-center pt-5 pb-5">
  //           <DatePicker
  //             showTime
  //             format="YYYY-MM-DD h:mm A"
  //             placeholder="Select Date and Time"
  //           />
  //         </div>
  //         <div className="d-flex justify-content-between align-items-center pt-5">
  //           <Text className="font-size-16">{t("sideBar.message")}</Text>
  //           <Text className="">
  //             <div>
  //               {t("sideBar.chars")} 0/160
  //               <span className="pl-5">{t("sideBar.msgUnits")} 0/10</span>
  //             </div>
  //           </Text>
  //         </div>
  //         <div className="d-flex align-items-end pb-5">
  //           <Input.TextArea
  //             rows={8}
  //             placeholder="Type your text here..."
  //             style={{ resize: "none" }}
  //           />
  //           <div
  //             className="d-flex align-items-end"
  //             style={{ marginLeft: -100, marginBottom: 10 }}
  //           >
  //             <Btn
  //               icon={
  //                 <Tooltip title={t("tootlTip.attachFile")}>
  //                   <PaperClipOutlined />
  //                 </Tooltip>
  //               }
  //               style={{ borderRadius: 50, marginLeft: 10 }}
  //             />
  //             <Btn
  //               icon={<ProfileOutlined />}
  //               style={{ borderRadius: 50, marginLeft: 10 }}
  //             />
  //           </div>
  //         </div>
  //       </div>
  //     ),
  //     okText: "Save",
  //     cancelText: "Cancel",
  //     style: { borderRadius: 1 },
  //     className: "border-radius",
  //     width: "755px",
  //   });
  // };

  useEffect(() => {
    if (userDetails && selectedUser) {
      fetchNewData();
      fetchNewEvents();
    }
  }, [userDetails]);

  const changeUser = (data: any) => {
    scrollRef.current.scrollTo({ top: 0, behavior: "smooth" });
    setItems(data);
  };

  const onFinishEditContact = (val: any) => {
    const param = {
      firstName: val?.firstName?.trim(),
      lastName: val?.lastName?.trim(),
      phoneNumber: val?.phoneNumber?.trim(),
      email: val?.email?.trim(),
      companyName: val?.companyName?.trim(),
    };

    dispatch(
      RequestAppAction.editUser({
        id: userDetails?.id,
        data: param,
        cbSuccess: (res: any) => {
          dispatch(setContactUser(res.data));
          notification.success({ message: t("notification.success") });
          dispatch(
            RequestAppAction.getUser({
              id: userDetails?.id,
              cbSuccess: () => {
                editContactModalRef.current?.closeModal();
              },
            })
          );
          // dispatch(
          //   RequestAppAction.fetchConversations({
          //     id: did,
          //     meta: { ...PAGINATION },
          //   })
          // );
          dispatch(
            RequestAppAction.fetchContacts({
              id: did,
              meta: { ...PAGINATION },
            })
          );
        },
        cbFailure: (e: string) => {
          notification.error({
            message: e ?? t("notification.editContactFail"),
          });
        },
      })
    );
  };

  const blockUser = () => {
    const param = {
      isBlocked: !userDetails?.isBlocked,
    };
    dispatch(
      RequestAppAction.editUser({
        id: userDetails?.id,
        data: param,
        cbSuccess: () => {
          dispatch(RequestAppAction.getUser({ id: userDetails?.id }));
          dispatch(
            RequestAppAction.fetchContacts({
              id: did,
              meta: { ...PAGINATION, search: "" },
              cbSuccess: () => {},
              cbFailure: (e: string) => {
                notification.error({
                  message: e ?? t("notification.editContactFail"),
                });
              },
            })
          );
          dispatch(
            RequestAppAction.fetchConversations({
              id: did,
              meta: PAGINATION,
            })
          );
          notification.success({ message: t("notification.success") });
        },
        cbFailure: (e: string) => {
          notification.error({
            message: e ?? t("notification.editContactFail"),
          });
        },
      })
    );
  };

  useEffect(() => {
    if (state) {
      const handler = setTimeout(() => {
        if (!PhoneNumberRegex.test(state)) {
          form.setFields([
            {
              name: "phoneNumber",
              errors: [t("notification.numberMustBeValid")],
            },
          ]);
        }
      }, 300);

      return () => {
        clearTimeout(handler);
      };
    }
  }, [state]);

  const validatePhoneNumber = () => {
    const phoneNumber = form.getFieldValue("phoneNumber");

    if (
      phoneNumber &&
      phoneNumber.length > 0 &&
      !PhoneNumberRegex.test(phoneNumber)
    ) {
      return Promise.reject(new Error(t("notification.numberMustBeValid")));
    }

    return Promise.resolve();
  };

  const handleSubmitEvents = async () => {
    try {
      await form
        .validateFields()
        .then((values) => {
          const data: {
            title: any;
            content: any;
            day: any;
            month: any;
            time: any;
            frequency?: string;
            fileLocations: never[];
          } = {
            title: values.title,
            content: values.content,
            day: values.day,
            month: values.month,
            time: values.time.toISOString(),
            frequency: values.frequency === 1 ? "ANNUALLY" : "MONTHLY",
            fileLocations: [],
          };
          switch (values?.frequency) {
            case "Anually":
              data["frequency"] = "ANNUALLY";
              break;
            case "Monthly":
              data["frequency"] = "MONTHLY";
              break;
            case 1:
              data["frequency"] = "ANNUALLY";
              break;
            case 2:
              data["frequency"] = "MONTHLY";
              break;
            default:
              data["frequency"] = "ANNUALLY";
              break;
          }

          if (attachments?.length > 0) {
            data["fileLocations"] = attachments;
          }
          if (!isEventEdit) {
            dispatch(
              RequestAppAction.postEvent({
                data: data,
                id: userDetails.id,
                cbSuccess: () => {
                  handleClose();
                  setAttachments([]);

                  if (fetchNewEvents) {
                    fetchNewEvents();
                  } else {
                    dispatch(
                      RequestAppAction.fetchEvent({
                        id: userDetails.id,
                        meta: PAGINATION,
                        cbSuccess: () => {
                          setPagination({ ...PAGINATION });
                        },
                        cbFailure: (e: string) => {
                          notification.error({
                            message: e ?? t("notification.postEventFail")
                          });
                        },
                      })
                    );
                    setPagination({ ...PAGINATION });
                  }
                  notification.success({
                    message: t("notification.saveScheduledSuccess"),
                  });
                },
              })
            );
          } else {
            dispatch(
              RequestAppAction.putEvent({
                data: data,
                id: values.eventId,
                cbSuccess: () => {
                  handleClose();
                  setAttachments([]);

                  if (fetchNewEvents) {
                    fetchNewEvents();
                  } else {
                    dispatch(
                      RequestAppAction.fetchEvent({
                        id: userDetails.id,
                        meta: PAGINATION,
                        cbSuccess: () => {
                          setPagination({ ...PAGINATION });
                        },
                        cbFailure: (e: string) => {
                          notification.error({
                            message: e ?? t("notification.getEventFail")
                          });
                        },
                      })
                    );
                    setPagination({ ...PAGINATION });
                  }
                  notification.success({
                    message: t("notification.saveScheduledSuccess"),
                  });
                },
              })
            );
          }
        })
        .catch(() => {
          return;
        });
    } catch (errorInfo: any) {
      notification.error({
        message: errorInfo ?? t("notification.saveScheduledFail")
      });
    }
  };

  const openEventEditModal = (eventValues: any) => {
    setIsEventEdit(true);
    setAttachments(eventValues?.fileLocations ?? []);
    setCharacters(eventValues?.content?.length);
    setFrequency(eventValues?.frequency === "ANNUALLY" ? 1 : 2);
    setTotalCharacters(eventValues?.content);
    form.setFieldsValue({
      ...eventValues,
      time: dayjs(eventValues?.time),
      eventId: eventValues.id,
      frequency:
        eventValues?.frequency === "ANNUALLY"
          ? options[0]?.label
          : options[1]?.label,
    });
    setTimeout(() => {
      openModal();
    }, 100);
  };

  const confirmDelete = async (id: number) => {
    dispatch(
      RequestAppAction.deleteEvent({
        id: id,
        cbSuccess: () => {
          fetchNewEvents();
          notification.warning({
            message: t("notification.deleteEvent"),
          });
          setPagination({ ...PAGINATION });
        },
      })
    );
  };

  useEffect(() => {
    if (isFileUploading || !uploadFileRef?.current) {
      return;
    }

    if (success) {
      if (attachments?.length > 0) {
        setAttachments((pre: any) => [...pre, ...success?.data]);
      } else {
        setAttachments([...success?.data]);
      }
    }
  }, [isFileUploading]);

  const handleMenuItemClick = (item: any) => {
    if (item.key === "Add New Template") {
      add();
    } else {
      form.setFieldValue("content", item?.content);
    }
  };

  const item: MenuProps["items"] = Array.isArray(templates?.items) ? templates.items.map(
    (template: { content: any; title: string }, index: any) => ({
      key: String(index),
      label: template.title,
      content: template?.content,
    })
  ) : [];

  const menu = () =>  (
    <Menu>
      {item && item.length > 0 && item.map((menuItem: any, index: number) => (
        <Menu.Item onClick={() => handleMenuItemClick(menuItem)} key={index}>
          {menuItem.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  // const fetchTemplateData = () => {
  //   dispatch(
  //     RequestAppAction.fetchTemplate({
  //       id: did,
  //       meta: PAGINATION,
  //       cbFailure: (e: string) => {
  //         notification.error({
  //           message: e ?? t("notification.getTemplateFail")
  //         });
  //       },
  //     })
  //   );
  // };

  // useEffect(() => {
  //   if (!did) {
  //     return;
  //   }

  //   if (
  //     templates
  //     && templates.length > 0
  //     && templates[0]?.didId === did
  //   ) {
  //     return;
  //   }

  //   fetchTemplateData();
  // }, [did]);

  useEffect(() => {
    if (!editId) {
      setEditValue(null);
    }
  }, [editId]);

  useEffect(() => {
    if (!isBulkModalVisible) {
      setUserData(null);
    }
  }, [isBulkModalVisible]);

  useEffect(() => {
    if (errorData?.length > 0) {
      ErrorRef?.current?.openModal();
    }
  }, [errorData]);

  const restrictedTooltip = (children: any) => {
    if (isPending || currentUserData?.isInboundOnly) {
      const toolTipTitle = isPending
        ? t("plan.notAvailableWhileActivationProcess")
        : t("plan.notAvailableWithThisPlan");

      return (
        <Tooltip title={toolTipTitle}>
          {children}
        </Tooltip>
      );
    }

    return children;
  };

  return (
    <>
      <Layout className="contact-page" style={{ background: Colors.reciverBg }}>
        <Sider
          width={width ? (width > 992 ? 450 : width > 768 ? 350 : width > 576 ? 300 : 250) : 450}
          style={{ background: Colors.BgListing }}
        >
          <ListingSidebarContacts
            openContactModal={openContactModal}
            onClickImport={() => attachContacts.current?.openModal("")}
            onPressContact={changeUser}
          />
          <ContactErrorModal
            data={errorData}
            setData={setErrorData}
            ref={ErrorRef}
          />
        </Sider>
        <TextifyModal
          afterClose={() => {
            setEditId(null);
          }}
          ref={modalRef}
        >
          <Note
            addFromResponse={addFromResponse}
            onEditSuccess={onEditSuccess}
            modalRef={modalRef}
            editId={editId}
            editValue={editValue}
            handleNotesLoading={handleNotesLoading}
            userDetails={userDetails}
          />
        </TextifyModal>

        <Content
          className="contact-content"
          style={{ background: Colors.white }}
        >
          <Row className="bg-primary header" style={{ padding: "1rem 1rem" }}>
            <Row
              className="header-row d-flex align-items-center justify-content-center"
              style={{ width: "100%" }}
            >
              <Avatar
                size={60}
                icon={
                  user?.firstName ? (
                    /^[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~][ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]*/.test(
                      user?.firstName
                    ) ? (
                      user?.firstName?.charAt(1).toUpperCase()
                    ) : (
                      user?.firstName?.charAt(0).toUpperCase()
                    )
                  ) : (
                    <UserOutlined />
                  )
                }
                style={{
                  background: Colors.IconBackground,
                  color: Colors.IconColor,
                  minWidth: "3.75rem",
                }}
                className="header-avatar"
              />
              {user && (
                <div className="header-name text-center">
                  <div style={{ whiteSpace: "normal" }}>
                    {(user.firstName || user.lastName) && (
                      <Text
                        style={{ maxWidth: '60%' }}
                        className="font-size-16 font-weight-300 text-white"
                      >
                        <span>{returnElipsesText(`${user.firstName} ${user.lastName || ""}`, 100, true)}</span>
                      </Text>
                    )}
                  </div>
                  {user.phoneNumber && (
                    <Text className="font-size-14 font-weight-300 text-white">
                      <span>{`(${user.phoneNumber})`}</span>
                    </Text>
                  )}
                  {user.email && (
                    <Text className="font-size-12 text-white">
                      {user.email}
                    </Text>
                  )}
                  {user.companyName && (
                    <Title
                      style={{ margin: 0 }}
                      className="font-size-16 text-white text-center"
                    >
                      <Text className="font-size-12 text-white">
                        {user.companyName}
                      </Text>
                    </Title>
                  )}
                </div>
              )}
              <div style={{ zIndex: 1 }} className="pl-5">
                <Btn
                  icon={<EditOutlined />}
                  style={{ borderRadius: 50 }}
                  onClick={() => {
                    if (user) {
                      form.setFieldsValue({
                        firstName: user?.firstName,
                        lastName: user?.lastName,
                        phoneNumber: user?.phoneNumber,
                        email: user?.email,
                        companyName: user?.companyName,
                      });
                      editContactModalRef.current?.openModal(
                        t("heading.editContact")
                      );
                    }
                  }}
                />
              </div>
            </Row>

            <Row
              className="d-flex align-items-center justify-content-right text-white"
              style={{ width: "100%" }}
            >
              {" "}
              {t("common.BlockorIgnoreBound")}{" "}
              <Checkbox
                onChange={() => {
                  blockUser();
                }}
                checked={userDetails?.isBlocked}
                className="pl-2"
              />
            </Row>
          </Row>
          <Content style={{ padding: "1rem 2rem 8rem" }}>
            <Divider
              orientation="left"
              orientationMargin="0"
              className="font-size-14 font-weight-300"
            >
              <div className="d-flex">
                Notes{" "}
                <Btn
                  className="d-flex align-items-center justify-content-center"
                  icon={<PlusCircleOutlined />}
                  onClick={add}
                  style={{ borderRadius: 100, marginLeft: 8 }}
                />
              </div>
            </Divider>
            <div
              ref={scrollRef}
              style={{
                height: "10rem",
                overflowY: "auto",
                paddingBottom: "2rem",
                border: `0.1rem solid ${Colors.LightGray}`,
                borderRadius: "0.3rem",
                padding: "1rem",
              }}
            >
              {Array.isArray(items?.items) && items?.items?.length > 0 ? (
                items.items.map((i: any, index: number) => (
                  <Card
                    key={index}
                    style={{
                      width: "100%",
                      borderRadius: "0.5rem",
                      marginBottom: 4,
                      borderColor: "transparent",
                      borderBottomColor: "transparent",
                    }}
                  >
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <div
                          style={{
                            maxWidth: "100%",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: "vertical",
                          }}
                        >
                          <p
                            className="font-size-16 font-weight-400"
                            style={{
                              margin: 0,
                            }}
                          >
                            {i?.content}
                          </p>
                        </div>
                        <p className="font-size-12 font-weight-400">
                          {i?.updatedAt && (
                            <span className="pl-3">
                              {formattedDateTime(
                                i.updatedAt,
                                preferences?.timeZone || "America/Toronto",
                                true,
                              )}
                            </span>
                          )}
                        </p>
                      </div>
                      <div>
                        <Space>
                          <Btn
                            onClick={() => {
                              setEditId(i?.id);
                              onEditNote(i);
                              setEditValue(i);
                            }}
                            icon={<EditOutlined />}
                            style={{ borderRadius: 50 }}
                          />
                          <Btn
                            onClick={() => {
                              onDelete(i.id);
                            }}
                            icon={<DeleteOutlined />}
                            style={{ borderRadius: 50 }}
                          />
                        </Space>
                      </div>
                    </div>
                    <div className="pt-2 pb-1">
                      <Divider />
                    </div>
                  </Card>
                ))
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    overflowY: "auto",
                    justifyContent: "center",
                    paddingTop: "4rem",
                    color: "#ccc",
                    fontSize: "1rem",
                    fontWeight: 300,
                  }}
                >
                  {t("placeholder.clickToAddNotes")}
                </div>
              )}
              {scrollFetch && <Spin> </Spin>}
            </div>

            <div style={{ marginTop: "2rem" }}>
              <Divider
                orientation="left"
                orientationMargin="0"
                className="font-size-14 font-weight-300"
              >
                Important Events{" "}
                {restrictedTooltip(
                  <Btn
                    icon={<PlusCircleOutlined />}
                    onClick={openModal}
                    style={{ borderRadius: 50, marginLeft: 8 }}
                    disabled={isPending || currentUserData?.isInboundOnly}
                  />
                )}
              </Divider>
            </div>
            <div
              style={{
                height: "10rem",
                overflowY: "auto",
                paddingBottom: "2rem",
                border: `0.1rem solid ${Colors.LightGray}`,
                borderRadius: "0.3rem",
                padding: "1rem",
              }}
            >
              {eventItems && eventItems?.items?.length > 0 ? (
                eventItems?.items?.map((eventItem: any, index: any) => (
                  <Card
                    key={index}
                    style={{
                      width: "100%",
                      borderRadius: "0.2rem",
                      borderColor: "transparent",
                    }}
                  >
                    <Row className="d-flex align-items-center justify-content-between">
                      <Col span={4}>
                        <p> {eventItem?.title}</p>
                      </Col>
                      <Col span={4}>
                        {eventItem?.time && (
                          <span className="pl-3">
                            {formattedDateTime(
                              eventItem.time,
                              preferences?.timeZone || "America/Toronto",
                              true,
                            )}
                          </span>
                        )}
                      </Col>
                      <Col span={11}>
                        <p className="font-size-12 font-weight-300">
                          {eventItem.content}
                        </p>
                      </Col>
                      <Col span={5} className="text-right">
                        <Space>
                          <Btn
                            icon={<EditOutlined />}
                            onClick={() => openEventEditModal(eventItem)}
                            style={{
                              borderRadius: 50,
                            }}
                          />
                          <Popconfirm
                            title="Delete the task"
                            description="Are you sure to delete this Event?"
                            onConfirm={() => confirmDelete(eventItem.id)}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Btn
                              icon={<DeleteOutlined />}
                              style={{
                                borderRadius: 50,
                              }}
                            />
                          </Popconfirm>
                        </Space>
                      </Col>
                    </Row>
                    <div className="pt-2 pb-1">
                      <Divider />
                    </div>
                  </Card>
                ))
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    overflowY: "auto",
                    justifyContent: "center",
                    paddingTop: "4rem",
                    color: "#ccc",
                    fontSize: "1rem",
                    fontWeight: 300,
                  }}
                >
                  {t("placeholder.clickToAddEvents")}
                </div>
              )}
            </div>
            <Space
              style={{
                width: "100%",
                padding: "1rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              align="center"
            >
              <Btn
                className="d-flex align-items-center justify-content-center"
                icon={<MessageOutlined />}
                onClick={() => {
                  if (selectedUser) {
                    dispatch(
                      RequestAppAction.fetchConversationsById({
                        id: user?.id,
                        meta: PAGINATION,
                        cbSuccess: (data: any) => {
                          if (data?.data) {
                            dispatch(setAllConversationsById(data));
                          }

                          if (data?.data?.meta?.totalCount) {
                            navigate("/conversations", { state: user });
                          } else {
                            setUserData(user);
                            setTimeout(() => {
                              openBulkModal();
                            }, 100);
                          }
                        },
                        cbFailure: (e: string) => {
                          notification.error({
                            message: e ?? t("notification.fetchConversationsByIdFail"),
                          });
                        },
                      })
                    );
                  }
                }}
                style={{ borderRadius: 100 }}
              />
            </Space>
            <AttachContacts setErrorData={setErrorData} ref={attachContacts} />
          </Content>
        </Content>
      </Layout>

      <div>{contextHolderDel}</div>
      <div>{contextHolderEdit}</div>

      <Modal
        centered
        open={isModalVisible}
        onCancel={handleClose}
        title={
          <Title className="font-size-24 font-weight-500">
            {isEventEdit
              ? t("modalHeading.updateImportantDate")
              : t("modalHeading.newImportantDate")}
          </Title>
        }
        footer={null}
        destroyOnClose
        className="contact-event-modal"
      >
        <Flex
          vertical
          gap={10}
          style={{
            padding: "1.3rem 0rem",
            width: "100%",
            position: "relative",
          }}
        >
          <Spin
            spinning={isFileUploading}
            style={{ height: "100%", width: "100%" }}
          >
            <Form form={form} initialValues={eventInitialValues}>
              <input
                ref={uploadFileRef}
                onChange={(e) => {
                  if (
                    e?.target?.files
                    && e.target.files.length > 0
                  ) {
                    setFiles(e.target.files);
                  }
                }}
                style={{ display: "none" }}
                type="file"
                multiple
              />

              <Form.Item name="eventId" hidden></Form.Item>
              <Space direction="vertical" className="pb-2">
                <Form.Item
                  name="title"
                  label={t("fields.dateTitle")}
                  rules={[{ required: true, message: "Please enter a title" }]}
                >
                  <Input placeholder="Ex. Birthday" />
                </Form.Item>
              </Space>
              <br />

              <Space className="pt-2 pb-2">
                <Form.Item name="frequency" style={{ width: "8rem" }}>
                  <Select
                    showSearch
                    placeholder="Frequency"
                    optionFilterProp="children"
                    onChange={(e) => {
                      setFrequency(e);
                      setSelectedMonth(1);
                      form.setFieldsValue({ day: null, month: null });
                    }}
                    filterOption={(input, option) =>
                      (option?.label ?? "").includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={options}
                  />
                </Form.Item>

                {frequency === 1 && (
                  <Form.Item
                    name="month"
                    style={{ width: "10rem" }}
                    rules={[
                      {
                        required: frequency === 1,
                        message: "Please enter a month",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      allowClear
                      placeholder="Month"
                      optionFilterProp="children"
                      onChange={(e) => handleMonthChange(e)}
                      filterOption={(input, option) =>
                        (option?.label ?? "").includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.value - optionB.value
                      }
                      options={months}
                    />
                  </Form.Item>
                )}
                <Form.Item
                  name="day"
                  style={{ width: "8rem" }}
                  rules={[{ required: true, message: "Please select date" }]}
                >
                  <Select
                    showSearch
                    allowClear
                    placeholder="Day"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "").includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.value - optionB.value
                    }
                    options={days}
                  />
                </Form.Item>

                <Form.Item
                  name="time"
                  rules={[{ required: true, message: "Please select a time" }]}
                >
                  <TimePicker use12Hours format="h:mm A" />
                </Form.Item>
              </Space>
              <div className="d-flex justify-content-between align-items-center pt-5">
                <Text className="font-size-16">{t("sideBar.message")}</Text>
                <Text>
                  {t("sideBar.chars")} {characters}/160
                  <span className="pl-5">
                    {t("sideBar.msgUnits")} {units}/10
                  </span>
                </Text>
              </div>
              <div
                style={{ position: "relative" }}
                className="d-flex align-items-end pb-5"
              >
                <Form.Item
                  name="content"
                  style={{ width: "-webkit-fill-available" }}
                  rules={[
                    { required: true, message: "Please enter some content" },
                  ]}
                >
                  <Input.TextArea
                    rows={8}
                    onChange={handleTextChange}
                    placeholder="Type your text here..."
                    style={{ resize: "none" }}
                  />
                </Form.Item>
                <div
                  className="d-flex position-absolute align-items-end justify-content-end"
                  style={{
                    bottom: "2.7rem",
                    right: "0.7rem",
                    position: "absolute",
                  }}
                >
                  <Btn
                    onClick={() => uploadFileRef.current?.click()}
                    icon={
                      <Tooltip title={t("tootlTip.attachFile")}>
                        <PaperClipOutlined />
                      </Tooltip>
                    }
                    style={{ borderRadius: 50, marginLeft: 10 }}
                  />
                  <Dropdown dropdownRender={menu} placement="topRight" arrow>
                    <Btn
                      icon={<ProfileOutlined />}
                      style={{ borderRadius: 50, marginLeft: 10 }}
                    />
                  </Dropdown>
                </div>
              </div>
              <Row>
                <Col
                  style={{
                    display: "flex",
                  }}
                  span={24}
                >
                  {Array.isArray(attachments) && attachments?.length > 0 &&
                    attachments.map((i: string, ind: number) => (
                      <div
                        style={{ position: "relative", marginRight: "0.5rem" }}
                      >
                        <Tag
                          style={{ background: Colors.white, borderRadius: 4 }}
                        >
                          <Text
                            key={ind}
                            className="font-size-14"
                            style={{
                              display: "flex",
                              cursor: "pointer  ",
                            }}
                          >
                            {returnElipsesText(i)}
                          </Text>
                        </Tag>
                        <CloseCircleFilled
                          onClick={() => {
                            const arr = attachments;
                            setAttachments(
                              arr.filter((item: any) => item !== i)
                            );
                          }}
                          style={{
                            color: Colors.red,
                            position: "absolute",
                            top: -10,
                            right: 0,
                            zIndex: 2,
                          }}
                        />
                      </div>
                    ))}
                </Col>
              </Row>
              <Row style={{ marginTop: "1rem" }} justify={"end"}>
                <Btn style={{ marginRight: "1rem" }} onClick={handleClose}>
                  {t("button.cancel")}
                </Btn>
                {units > 10 ? (
                  <Tooltip
                    trigger={"hover"}
                    color="red"
                    title={t("message.unitError")}
                  >
                    <Btn type="primary" disabled={true}>
                      {t("button.save")}
                    </Btn>
                  </Tooltip>
                ) : (
                  <Btn
                    key="submit"
                    type="primary"
                    onClick={() => handleSubmitEvents()}
                  >
                    {t("button.save")}
                  </Btn>
                )}
              </Row>
            </Form>
          </Spin>
        </Flex>
      </Modal>

      {/* new contact Modal */}
      <NewContactModal ref={addNewContactRef} />

      <TextifyModal ref={editContactModalRef}>
        <Spin spinning={isLoading}>
          <div style={{ paddingTop: 25, paddingBottom: 15 }}>
            <Form onFinish={onFinishEditContact} name="userForm" form={form}>
              <Form.Item
                rules={[{ required: true, message: t("fields.nameError") }]}
                name={"firstName"}
                initialValue={userDetails?.firstName}
              >
                <Input
                  placeholder="Enter Name"
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  suffix={
                    <CloseCircleFilled
                      onClick={() => {
                        form.setFieldsValue({ firstName: "" });
                      }}
                      style={{ color: "rgba(0,0,0,.45)" }}
                    />
                  }
                />
              </Form.Item>
              <Form.Item name={"lastName"} initialValue={userDetails?.lastName}>
                <Input
                  placeholder="Enter Last Name"
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  suffix={
                    <CloseCircleFilled
                      onClick={() => {
                        form.setFieldsValue({ lastName: "" });
                      }}
                      style={{ color: "rgba(0,0,0,.45)" }}
                    />
                  }
                />
              </Form.Item>
              <Form.Item
                name={"phoneNumber"}
                rules={[
                  { required: true, message: t("fields.numberError") },
                  { validator: validatePhoneNumber }
                ]}
                initialValue={userDetails?.phoneNumber}
              >
                <Input
                  onChange={(e) => {
                    setState(e.target.value);
                  }}
                  // onKeyDown={(e) => {
                  //   const key = e.key;
                  //   if (
                  //     !/[0-9]/.test(key) &&
                  //     key !== "ArrowLeft" &&
                  //     key !== "ArrowRight" &&
                  //     key !== "+" &&
                  //     key !== "Backspace"
                  //   ) {
                  //     e.preventDefault();
                  //   }
                  // }}
                  placeholder="Phone Number"
                  prefix={<PhoneOutlined className="site-form-item-icon" />}
                  suffix={
                    <CloseCircleFilled
                      onClick={() => {
                        form.setFieldsValue({ phoneNumber: "" });
                      }}
                      style={{ color: "rgba(0,0,0,.45)" }}
                    />
                  }
                />
              </Form.Item>
              <Form.Item name={"email"} initialValue={userDetails?.email}>
                <Input
                  placeholder="Email"
                  prefix={<MailOutlined className="site-form-item-icon" />}
                  suffix={
                    <CloseCircleFilled
                      onClick={() => {
                        form.setFieldsValue({ email: "" });
                      }}
                      style={{ color: "rgba(0,0,0,.45)" }}
                    />
                  }
                />
              </Form.Item>
              <Form.Item
                name={"companyName"}
                initialValue={userDetails?.companyName}
              >
                <Input
                  placeholder="Company Name"
                  suffix={
                    <CloseCircleFilled
                      onClick={() => {
                        form.setFieldsValue({ companyName: "" });
                      }}
                      style={{ color: "rgba(0,0,0,.45)" }}
                    />
                  }
                />
              </Form.Item>
              <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                <Button buttonType="submit" title={t("button.save")} />
                <div style={{ marginRight: "1rem" }}>
                  <Button
                    btnClass="outlined_btn"
                    onBtnClick={() => {
                      editContactModalRef.current?.closeModal();
                    }}
                    title={t("button.cancel")}
                  />
                </div>
              </div>
            </Form>
          </div>
        </Spin>
      </TextifyModal>
      <BulkMessageModal
        isModalVisible={isBulkModalVisible}
        setIsModalVisible={setIsBulkModalVisible}
        userData={userData}
        loading={loading}
        setLoading={setLoading}
      />
    </>
  );
};

export default Contacts;
