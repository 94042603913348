import { AxiosResponse } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";
import { fetchDidFailure, fetchDidSuccess } from "../slices/features/fetchDids";
import { RequestTypes } from "../types";
import { Status } from "src/constants/common";

const appservice = new AppService();

function* fetchDidSaga(action: any) {
  const { payload } = action;
  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      appservice.fetchUserDetails,
      baseUrl
    );

    if (response?.data?.dids && response.data.dids.length > 0) {
      const dids = response.data.dids.filter(
        (item: any) => 
          item.deletedAt === null && item.status !== Status.INACTIVE
      );
      response.data.dids = dids;
    }

    yield put(fetchDidSuccess({ ...response }));

    payload?.cbSuccess && payload?.cbSuccess();
  } catch (error: any) {
    const { statusCode, statusText } = error;
    yield put(fetchDidFailure({ statusCode, statusText }));
  }
}

export function* watchfetchDid() {
  yield takeLatest(RequestTypes.GET_DIDS_REQUEST, fetchDidSaga);
}
