import { Button, Form } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatAddress } from "src/utils/format";
import EditCompanyBlock from "./EditCompanyBlock";
import styles from "src/pages/subscribe/subscribe.module.scss";

interface IEditCompanyInfo {
  form: any;
  setEditCompanyInfo: (editUserInfo: boolean) => void;
  userData?: any;
  setUserData: (payloadData: any) => void;
  signUpUserFormData?: any;
  setSignUpUserFormData: (signUpUserFormData: any) => void;
  onCancel: () => void;
}

const EditCompanyInfo = ({
  form,
  setEditCompanyInfo,
  userData,
  setUserData,
  signUpUserFormData,
  setSignUpUserFormData,
  onCancel,
}: IEditCompanyInfo) => {
  const { t } = useTranslation();

  const [selectedCountry, setSelectedCountry] = useState<string | undefined>();

  useEffect(() => {
    if (signUpUserFormData) {
      form.setFieldsValue(signUpUserFormData);

      if (signUpUserFormData.country) {
        setSelectedCountry(signUpUserFormData.country);

        if (signUpUserFormData.country === "CA") {
          form.setFieldsValue({ province: signUpUserFormData.state });
          form.resetFields(["state"]);
        }
      }
    }
  }, [signUpUserFormData]);

  const onFinish = (values: any) => {
    let newSignUpUserFormData: any = signUpUserFormData;
    newSignUpUserFormData = {
      ...newSignUpUserFormData,
      ...values,
    };
    setSignUpUserFormData(newSignUpUserFormData);

    const {
      companyName,
      billingEmailAddress,
      timezone,
      country,
      province,
      state,
      city,
      postalCode,
      howDidYouHear
    } = values;

    const address = formatAddress(
      postalCode,
      country || selectedCountry,
      province,
      state,
      city,
    );

    const payloadData: any = userData;
    payloadData.name = companyName;
    payloadData.timeZone = timezone;
    payloadData.country = address.country;
    payloadData.postalCode = address.postalCode;
    payloadData.state = address.state;
    payloadData.city = address.city;
    payloadData.billingEmailAddress = billingEmailAddress;
    payloadData.howDidYouHear = howDidYouHear;

    setUserData(payloadData);
    setEditCompanyInfo(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div>
      <Form
        form={form}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className={styles.sign_up_form}
      >
        <EditCompanyBlock
          form={form}
          selectedCountry={selectedCountry}
          setSelectedCountry={setSelectedCountry}
        />
        <Form.Item className={`${styles.review_item} ${styles.review_btn}`}>
          <Button
            type="primary"
            htmlType="submit"
            className={styles.primary}
          >
            {t("button.save")}
          </Button>
        </Form.Item>
        <Form.Item className={`${styles.review_item} ${styles.review_btn}`}>
          <Button
            style={{
              marginTop: "12px",
              border: "1px solid #FAEBD5"
            }}
            className={styles.secondary}
            onClick={onCancel}
          >
            {t("button.cancel")}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default EditCompanyInfo;
