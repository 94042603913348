import { MSG_CHAR_LIMIT } from "src/constants/common";

export const capitalizeFirstLetter = (str: string) => {
  return str?.charAt(0).toUpperCase() + str?.slice(1);
};

export const getOptionsList = (options: any) => {
  const list = [];

  if (options && Object.keys(options).length > 0) {
    for (const key in options) {
      list.push({
        key,
        label: options[key as keyof any], value: key
      });
    }
  }

  return list;
};

export const calculateUnitCharacters = (value: string, attachments: any = null) => {
  const charactersLength = value.length;
  const totalUnits = Math.floor(charactersLength / MSG_CHAR_LIMIT);
  const attachmentArr = Array.isArray(attachments) ? attachments : [];

  let valueUnits = 0;

  if (charactersLength > 0) {
    valueUnits = totalUnits + 1 + attachmentArr.length * 3;
  } else if (attachmentArr.length > 0) {
    valueUnits = attachmentArr.length * 3;
  }

  return {
    valueUnits: valueUnits,
    valueChars: charactersLength - (totalUnits || 0) * MSG_CHAR_LIMIT,
  };
};
