import { Card } from "antd";
import Title from "antd/es/typography/Title";
import { useTranslation } from "react-i18next";
import Button from "../button";
import styles from "./index.module.scss";

interface IDashboardFullNote {
  isOpen: boolean,
  setIsOpen: (open: boolean) => void,
}

function DashboardFullNote({ isOpen = false, setIsOpen }: IDashboardFullNote) {
  const { t } = useTranslation();

  if (!isOpen) {
    return <></>;
  }

  return (
    <div className={styles.dashboard_note}>
      <Card className={`${styles.card} ${styles.card_shadow}`}>
        <div className="d-flex-column align-items-center justify-content-between">
          <Title
            level={5}
            className="font-size-22 font-weight-500"
            style={{
              marginBottom: "1rem",
              textAlign: "center",
            }}
          >
            {t("modal.activationIsBeingProcessed")}
            <br />
            {t("modal.onceActivationIsCompleted")}
          </Title>
          <Button
            title={t("button.close")}
            onBtnClick={() => setIsOpen(false)}
          />
        </div>
      </Card>
    </div>
  );
};

export default DashboardFullNote;
