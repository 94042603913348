import { CloseOutlined, PlusCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Modal,
  Space,
  Tag,
  TimePicker,
  Typography,
  notification,
} from "antd";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { MSG_CHAR_LIMIT, MSG_UNIT_LIMIT } from "src/constants/common";
import { calculateTimeDifferenceInAMPM } from "src/constants/functions";
import { calculateUnitCharacters } from "src/utils/helper-functions";
import useWindowDimensions from "src/hooks/useWindowDimensions";
import { getCurrentDidId } from "src/store/selectors/features/currentDid";
import RequestAppAction from "src/store/slices/appActions";

const { Title } = Typography;

interface weekDaysProps {
  weekDays: Array<{ index: number; name: string }>;
}

const WorkDaysModal = forwardRef(({ weekDays }: weekDaysProps, ref: any) => {
  const { width } = useWindowDimensions();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = useForm();

  const did = useSelector(getCurrentDidId);

  const [show, setShow] = useState(false);
  const [workingHours, setWorkingHours] = useState<any>([0]);
  const [fullDay, setFullDay] = useState(false);
  const [onPress, setOnPress] = useState<{
    name: string;
    index: number;
  } | null>(null);
  const [selectedDays, setSelectedDays] = useState<Array<number | null>>([]);
  const [inputValue, setInputValue] = useState<string>("");
  const [units, setUnits] = useState<number>(0);
  const [characters, setCharacters] = useState<number>(0);

  useImperativeHandle(ref, () => ({
    closeModal: () => {
      setShow(false);
    },
    openModal: () => {
      setShow(true);
    },
  }));

  useEffect(() => {
    if (!show) {
      setSelectedDays([]);
      setWorkingHours([0]);
      setFullDay(false);
      setInputValue("");
      setUnits(0);
      setCharacters(0);
      form.resetFields();
    }
  }, [show]);

  useEffect(() => {
    if (onPress) {
      const arr: any = selectedDays;

      const hasTestObject = arr.some(
        (obj: { name: string }) => obj.name === onPress?.name
      );

      if (hasTestObject) {
        setSelectedDays(
          arr?.filter((i: { name: string }) => i?.name !== onPress.name)
        );
      } else {
        arr?.push(onPress);
        setSelectedDays(arr);
      }

      setOnPress(null);
    }
  }, [onPress]);

  useEffect(() => {
    if (fullDay) {
      setWorkingHours([0]);
      form.setFieldsValue({
        ["from_0"]: dayjs().set("hour", 0).set("minute", 0),
        ["to_0"]: dayjs().set("hour", 23).set("minute", 59),
      });
    }
  }, [fullDay]);

  useEffect(() => {
    const { valueUnits, valueChars } = calculateUnitCharacters(inputValue);
    setUnits(valueUnits);
    setCharacters(valueChars);
  }, [inputValue]);

  const onFinish = () => {
    if (selectedDays.length < 1) {
      form.setFields([
        {
          name: "weekDays",
          errors: [t("fields.dayError")],
        },
      ]);
    }
    form
      .validateFields()
      .then((val) => {
        const params: {
          weekdays: number[];
          content: string;
          workingHours: any[];
          twentyFourHours?: boolean;
        } = {
          weekdays: selectedDays.map((i: any) => i.index),
          content: val.content,
          workingHours: workingHours.map((_: any, item: number) => {
            const fromProperty = `from_${item}`;
            const toProperty = `to_${item}`;

            return {
              from: dayjs(val[fromProperty]?.$d).format("HH:mm"),
              to: dayjs(val[toProperty]?.$d).format("HH:mm"),
            };
          }),
        };

        workingHours?.map((_: any, i: any) => {
          const fromVal = val[`from_${i}`]?.$d;
          const toVal = val[`to_${i}`]?.$d;

          if (calculateTimeDifferenceInAMPM(fromVal, toVal) || fullDay) {
            params["twentyFourHours"] = true;
            params["workingHours"] = [
              {
                from: dayjs().set("hour", 0).set("minute", 0).format("HH:mm"),
                to: dayjs().set("hour", 23).set("minute", 59).format("HH:mm"),
              },
            ];
            return;
          } else {
            params["twentyFourHours"] = false;
            return;
          }
        });

        dispatch(
          RequestAppAction.postWorkingDays({
            id: did,
            data: params,
            cbSuccess: () => {
              dispatch(RequestAppAction.getWorkingDays({ id: did }));
              notification.success({ message: t("notification.success") });
              setShow(false);
            },
            cbFailure: (e: string) => {
              notification.error({
                message: e ?? t("notification.postWorkingDaysFail")
              });
            },
          })
        );
      })
      .catch(() => {});
  };

  return (
    <Modal
      title={
        <Title level={4} className="font-size-20 font-weight-500">
          {" "}
          {t("modalHeading.timeOfDayAutoresponderSettings")}{" "}
        </Title>
      }
      open={show}
      centered
      width={700}
      destroyOnClose
      onOk={() => {
        onFinish();
      }}
      closeIcon={
        <CloseOutlined
          onClick={() => {
            setShow(false);
            setSelectedDays([]);
          }}
        />
      }
      onCancel={() => {
        setShow(false);
      }}
      okText={t("button.save")}
      cancelText={t("button.cancel")}
      className="time-autoresponder-setting"
    >
      <Form form={form} name="workingDays" requiredMark={false}>
        <div className="pt-5 pb-5">
          <Space direction="vertical" size={20} style={{ width: "100%" }}>
            <div>
              <Title level={5} className="font-size-16 font-weight-500">
                {t("fields.autoresponderMessage")}
              </Title>
              <div
                style={{ marginBottom: "-1rem", width: "100%" }}
                className={`counter-text ${width > 310 ? "d-flex" : "d-flex-column align-items-start"} font-size-14`}
              >
                <div>
                  {t("sideBar.chars")} {characters}/{MSG_CHAR_LIMIT}
                </div>
                <div className={width > 310 ? "pl-3" : "pl-0"}>
                  {t("sideBar.msgUnits")} {units}/{MSG_UNIT_LIMIT}
                </div>
              </div>
            </div>
            <Form.Item
              name={"content"}
              label=""
              hasFeedback
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[{ required: true, message: t("fields.messageError") }]}
            >
              <Input.TextArea
                rows={4}
                placeholder=""
                onKeyDown={() => {
                  if (units > MSG_UNIT_LIMIT) return;
                }}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                style={{ resize: "none" }}
              />
            </Form.Item>
            <Space direction="vertical" size={8}>
              <Title level={5} className="font-size-16 font-weight-500">
                {" "}
                {t("heading.setTheTimeWhenResponderShouldBeActive")}.
              </Title>

              {workingHours?.map((_: any, index: number) => {
                return (
                  <Space key={index} wrap>
                    <Form.Item
                      rules={[
                        { required: !fullDay, message: t("fields.timeError") },
                      ]}
                      name={`from_${index}`}
                    >
                      <TimePicker
                        placeholder={t("timePicker.startTime")}
                        onOk={() => {
                          setTimeout(() => {
                            workingHours?.map((_: any, index: number) => {
                              if (
                                calculateTimeDifferenceInAMPM(
                                  form.getFieldValue(`from_${index}`),
                                  form.getFieldValue(`to_${index}`)
                                )
                              ) {
                                setFullDay(true);
                              }
                            });
                          }, 200);
                        }}
                        disabled={fullDay}
                        use12Hours
                        format="h:mm A"
                      />
                    </Form.Item>
                    <Form.Item
                      rules={[
                        { required: !fullDay, message: t("fields.timeError") },
                      ]}
                      name={`to_${index}`}
                    >
                      <TimePicker
                        placeholder={t("timePicker.endTime")}
                        onOk={() => {
                          setTimeout(() => {
                            workingHours?.map((_: any, index: number) => {
                              if (
                                calculateTimeDifferenceInAMPM(
                                  form.getFieldValue(`from_${index}`),
                                  form.getFieldValue(`to_${index}`)
                                )
                              ) {
                                setFullDay(true);
                              }
                            });
                          }, 200);
                        }}
                        disabled={fullDay}
                        use12Hours
                        format="h:mm A"
                      />
                    </Form.Item>
                    {index === 0 ? (
                      <div style={{ marginBottom: "1.6rem" }}>
                        <Button
                          disabled={fullDay}
                          onClick={() => {
                            if (workingHours.length < 3) {
                              setWorkingHours((pre: any) => [...pre, index]);
                            }
                          }}
                          type="primary"
                          icon={<PlusCircleOutlined />}
                        >
                          {" "}
                          {t("button.addAdditionalTime")}
                        </Button>
                        <Checkbox
                          checked={fullDay}
                          onChange={() => {
                            setFullDay(!fullDay);
                          }}
                          style={{ marginLeft: "0.5rem" }}
                          className="font-size-12 font-weight-400"
                        >
                          {t("common.24Hours")}
                        </Checkbox>
                      </div>
                    ) : (
                      <div style={{ marginBottom: "1.6rem" }}>
                        <CloseOutlined
                          onClick={() => {
                            const arr = workingHours.filter(
                              (_: any, ind: number) => ind != index
                            );

                            setWorkingHours(arr);
                          }}
                        />
                      </div>
                    )}
                  </Space>
                );
              })}
            </Space>
            <Title level={5} className="font-size-16 font-weight-500">
              {" "}
              {t("heading.setTheDayThatThisAutoresponderShouldBeActive")}
            </Title>
            <Form.Item style={{ width: "100%" }} name={"weekDays"}>
              <Space style={{ display: "flex", justifyContent: "center" }} wrap>
                {weekDays?.map((i, index) => (
                  <Tag
                    onClick={() => {
                      setOnPress(i);
                    }}
                    key={index}
                    className={
                      selectedDays.some((obj: any) => obj.name === i.name)
                        ? "selected-tag"
                        : "custom-tag"
                    }
                  >
                    {i.name}
                  </Tag>
                ))}
              </Space>
            </Form.Item>
          </Space>
        </div>
      </Form>
    </Modal>
  );
});

export default WorkDaysModal;
