import { AxiosResponse } from "axios";
import { notification } from "antd";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";
import {
  createCompanyFailure,
  createCompanySuccess,
} from "../slices/features/createCompany";
import { RequestTypes } from "../types";

const createCompanyService = new AppService();

function* createCompany(action: any) {
  const { payload } = action;

  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      createCompanyService.createCompany,
      baseUrl,
      payload
    );

    notification.success({ message: "Company created successfully!" });

    yield put(createCompanySuccess({ ...response }));
    payload?.cbSuccess && payload?.cbSuccess({ ...response });
  } catch (error: any) {
    const { statusCode, statusText } = error;

    let message = '';

    if (error?.data?.errors?.message) {
      if (Array.isArray(error?.data?.errors?.message)) {
        message = error.data.errors.message[0];
      } else {
        message = error.data.errors.message;
      }
    } else {
      message = "Error creating company.";
    }

    notification.error({ message });
    payload?.cbFailure && payload?.cbFailure(message);

    yield put(createCompanyFailure({ statusCode, statusText }));
  }
}

export function* watchCreateCompany() {
  yield takeLatest(RequestTypes.CREATE_COMPANY, createCompany);
}
