import dayjs from "dayjs";

export const formattedDateTime = (
  timestamp: string,
  userTimeZone?: string,
  includeSeconds?: boolean,
) => {
  if (!timestamp) {
    return '';
  }

  const date = new Date(timestamp);

  const timeOptions: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZoneName: "short",
    timeZone: userTimeZone,
  };

  if (!includeSeconds) {
    timeOptions["second"] = "numeric";
  }

  const formatter = new Intl.DateTimeFormat(undefined, {
    ...timeOptions,
    timeZoneName: undefined,
  });

  const parts = formatter.formatToParts(date);
  const getObject = (type: keyof Intl.DateTimeFormatPartTypesRegistry) => {
    return parts.find(part => part.type === type)?.value || '';
  };
  const year = getObject('year');
  const month = getObject('month');
  const day = getObject('day');
  const hour = getObject('hour');
  const minute = getObject('minute');
  const second = includeSeconds ? getObject('second') : '';
  const dayPeriod = getObject('dayPeriod');

  const formattedTime = `${year}-${month}-${day} ${hour}:${minute}${second ? ':' + second : ''} ${dayPeriod}`;

  return formattedTime;
};

export const formattedTime = (timestamp: string, second?: boolean) => {
  const date = new Date(timestamp);

  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZoneName: "short",
  };

  if (!second) {
    timeOptions["second"] = "numeric";
  }

  const formatter = new Intl.DateTimeFormat(undefined, timeOptions);
  const timeZone = formatter.resolvedOptions().timeZone;

  const formattedTime = new Intl.DateTimeFormat(undefined, {
    ...timeOptions,
    timeZoneName: undefined,
    timeZone,
  }).format(date);

  return formattedTime;
};

export const returnElipsesText = (text: string, maxLength = 5, isEnd: boolean = false) => {
  const ellipsis = "...";
  let resultString = "";

  if (!text || text.length === 0) {
    return resultString;
  }

  if (text.length <= maxLength) {
    resultString = text;
  } else if (isEnd) {
    resultString = text.slice(0, Math.min(text.length, maxLength)) + ellipsis;
  } else {
    const extractedString = text?.slice(-maxLength);
    resultString =
      extractedString.slice(0, maxLength) + ellipsis + text.slice(text.length - 3);
  }

  return resultString;
};

export const returnEllipsesFileName = (text: string) => {
  if (!text) {
    return "";
  }

  const extensionIndex = text.lastIndexOf(".");
  const fileName = text.slice(0, extensionIndex);
  const extension = text.slice(extensionIndex);

  if (fileName.length > 5) {
    return fileName.slice(0, 5) + "..." + extension;
  } else {
    return text;
  }
};

export function calculateTimeDifferenceInAMPM(date1: any, date2: any) {
  const parsedDate1 = dayjs(date1);
  const parsedDate2 = dayjs(date2);
  const minFrom = parsedDate1.minute();
  const minTo = parsedDate2.minute();
  const hourFrom = parsedDate1.hour();
  const hourTo = parsedDate2.hour();

  const ft = dayjs(`2000-01-01 ${hourFrom}:${minFrom}`);
  const tt = dayjs(`2000-01-01 ${hourTo}:${minTo}`);

  const mins = tt.diff(ft, "minutes", true);
  const totalHours = mins / 60;

  if (
    (totalHours.toFixed() === "24" && minTo === 59) ||
    (totalHours.toFixed() === "-24" && minFrom === 59)
  ) {
    return true;
  }

  return false;
}

export const dateFormat = (date: any) => {
  const newDate = new Date(date.getTime());
  newDate.setUTCDate(newDate.getUTCDate() + 1);

  return newDate.toISOString().split("T")[0];
};

export const dateToUtc = (date: any) => {
  const newDate = new Date(date.getTime());
  newDate.setUTCDate(newDate.getUTCDate() + 1);

  return newDate.toISOString().split("T")[0];
};

export function formatTimeToAMPM(dateTimeString: string) {
  var [hours, minutes] = dateTimeString.split(":").map(Number);

  var period = hours < 12 ? "AM" : "PM";

  hours = hours % 12;
  if (hours === 0) {
    hours = 12;
  }

  let Min = minutes < 10 ? `0${minutes}` : minutes;
  let Hour = hours < 10 ? `0${hours}` : hours;

  return `${Hour}:${Min} ${period}`;
}

export function capitalize(str: string) {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  } else {
    return "";
  }
}

export const isValidFile = (file: string) => {
  if (!file || file.length === 0) {
    return false;
  }

  const filename = file.toLowerCase();

  if (
    filename.endsWith(".png") ||
    filename.endsWith(".jpg") ||
    filename.endsWith(".jpeg") ||
    filename.endsWith(".jpe") ||
    filename.endsWith(".jif") ||
    filename.endsWith(".jfif") ||
    filename.endsWith(".jfi") ||
    filename.endsWith(".gif") ||
    filename.endsWith(".svg") ||
    filename.endsWith(".svgz") ||
    filename.endsWith(".webp")
  ) {
    return true;
  }

  return false;
};
