import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Space,
  Switch,
  Tag,
  Typography,
  notification,
} from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { MSG_CHAR_LIMIT, MSG_UNIT_LIMIT } from "src/constants/common";
import { calculateUnitCharacters } from "src/utils/helper-functions";
import useWindowDimensions from "src/hooks/useWindowDimensions";
import {
  getAutoresponderKeywordData,
  getAutoresponderKeywordLoading,
} from "src/store/selectors/features/autoresponderKeyword";
import { getCurrentDidId } from "src/store/selectors/features/currentDid";
import RequestAppAction from "src/store/slices/appActions";
import SettingsLayout from "src/layouts/settings";
import "../../setting.scss";

const { Title, Text } = Typography;

const KeyWords: React.FC = () => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const [form] = useForm();

  const currentDid = useSelector(getCurrentDidId);
  const keywords: any = useSelector(getAutoresponderKeywordData);
  const isLoading = useSelector(getAutoresponderKeywordLoading);

  const [open, setOpen] = useState(false);
  const [keywordsArray, setKeywordsArray] = useState<string[]>([]);
  const [selectedKeyWord, setSelectedKeyWord] = useState<any>({});
  const [inputValue, setInputValue] = useState("");
  const [editValue, setEditValue] = useState<string>("");
  const [units, setUnits] = useState<number>(0);
  const [characters, setCharacters] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [loadingId, setLoadingId] = useState(null);
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    if (!isLoading) {
      setLoading(false);
      setLoadingId(null);
    }
  }, [isLoading]);

  useEffect(() => {
    if (selectedKeyWord) {
      form.setFieldsValue({
        ...selectedKeyWord,
        keywordsArray: selectedKeyWord?.keywords?.split(","),
        content: selectedKeyWord.content,
      });
      setKeywordsArray(selectedKeyWord?.keywords?.split(","));

      if (selectedKeyWord.keywords) {
        setEditValue(selectedKeyWord.content || "");
        const { valueUnits, valueChars } = calculateUnitCharacters(selectedKeyWord.content || "");
        setUnits(valueUnits);
        setCharacters(valueChars);
      } else {
        setEditValue("");
        setUnits(0);
        setCharacters(0);
      }
    }
  }, [selectedKeyWord]);

  useEffect(() => {
    if (currentDid) {
      dispatch(
        RequestAppAction.fetchAutoresponderKeyword({
          id: currentDid,
          cbSuccess: () => {
            setInitialLoad(false);
          },
          cbFailure: (e: string) => {
            notification.error({
              message: e ?? t("notification.getAutoResponderKeywordFail")
            });
          },
        })
      );
    }
  }, [currentDid]);

  useEffect(() => {
    return () => {
      setInitialLoad(true);
    };
  }, []);

  useEffect(() => {
    if (!open) {
      setInputValue("");
      setUnits(0);
      setCharacters(0);
    }
  }, [open]);

  useEffect(() => {
    const { valueUnits, valueChars } = calculateUnitCharacters(inputValue);
    setUnits(valueUnits);
    setCharacters(valueChars);
  }, [inputValue]);

  useEffect(() => {
    const { valueUnits, valueChars } = calculateUnitCharacters(editValue);
    setUnits(valueUnits);
    setCharacters(valueChars);
  }, [editValue]);

  const handleSubmit = async () => {
    if (keywordsArray?.length > 0 && inputValue.length === 0) {
      try {
        await form.validateFields();
        const { name, content, keywordsArray } = form.getFieldsValue();
        const data = {
          name,
          content,
          keywordsArray,
          isActive: true,
        };
        if (Object.keys(selectedKeyWord).length > 0) {
          dispatch(
            RequestAppAction.putAutoresponderKeyword({
              data: { name, content, keywordsArray, isActive: true },
              id: selectedKeyWord?.id,
              cbSuccess: () => {
                dispatch(
                  RequestAppAction.fetchAutoresponderKeyword({
                    id: currentDid,
                    cbFailure: (e: string) => {
                      notification.error({
                        message: e ?? t("notification.getAutoResponderKeywordFail")
                      });
                    },
                  })
                );
                form.resetFields();
                notification.success({
                  message: t("notification.editAutoResponderKeywordSuccess"),
                });
                hideModal();
              },
              cbFailure: (e: string) => {
                notification.error({
                  message: e ?? t("notification.editAutoResponderKeywordFail")
                });
              },
            })
          );
        } else {
          dispatch(
            RequestAppAction.postAutoresponderKeyword({
              data: data,
              id: currentDid,
              cbSuccess: () => {
                dispatch(
                  RequestAppAction.fetchAutoresponderKeyword({
                    id: currentDid,
                    cbFailure: (e: string) => {
                      notification.error({
                        message: e ?? t("notification.getAutoResponderKeywordFail")
                      });
                    },
                  })
                );
                form.resetFields();
                notification.success({
                  message: t("notification.saveAutoResponderKeywordSuccess"),
                });
                hideModal();
              },
              cbFailure: (e: string) => {
                notification.error({
                  message: e ?? t("notification.saveAutoResponderKeywordFail")
                });
              },
            })
          );
        }
      } catch (e: any) {
        return e;
      }
    } else {
      form.setFields([
        {
          name: "keywordsArray",
          errors: [t("common.pressEnter")],
        },
      ]);
    }
  };

  const confirmDelete = (id: any) => {
    dispatch(
      RequestAppAction.deleteAutoresponderKeyword({
        id: id,
        cbSuccess: () => {
          notification.warning({
            message: t("notification.deleteAutoResponderKeywordSuccess")
          });
          dispatch(
            RequestAppAction.fetchAutoresponderKeyword({
              id: currentDid,
              cbFailure: (e: string) => {
                notification.error({
                  message: e ?? t("notification.getAutoResponderKeywordFail")
                });
              },
            })
          );
        },
        cbFailure: (e: string) => {
          notification.error({
            message: e ?? t("notification.deleteAutoResponderKeywordFail")
          });
        },
      })
    );
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const trimmedValue = inputValue.trim();
    if (e.key === "Enter" && trimmedValue) {
      form.setFieldValue("keywordsArray", [
        ...(keywordsArray || []),
        trimmedValue,
      ]);
      setKeywordsArray((prevKeywords) => [
        ...(prevKeywords || []),
        trimmedValue,
      ]);
      setInputValue("");
    }
  };

  const handleTagClose = (removedTag: string) => {
    form.setFieldValue(
      "keywordsArray",
      keywordsArray.filter((keyword) => keyword !== removedTag)
    );
    setKeywordsArray((prevKeywords) =>
      prevKeywords.filter((keyword) => keyword !== removedTag)
    );
  };

  const showModal = () => {
    setOpen(true);
  };

  const hideModal = () => {
    setOpen(false);
    setSelectedKeyWord({});
    form.resetFields();
    setKeywordsArray([]);
  };

  return (
    <SettingsLayout isCard={false} classname="keywords-page">
      <Card className="keywords-card">
        <Row className="d-flex justify-content-center">
          <Col span={24}>
            {Array.isArray(keywords) && keywords?.length > 0 ? (
              keywords?.map((keyword: any, index: any) => (
                <Card className="keywords-card-shadow" key={index}>
                  <Row
                    style={{ gap: "0.5rem"}}
                    className={`${width > 576 
                      ? "d-flex align-items-center justify-content-between"
                      : "d-flex-column align-items-start justify-content-center"
                    }`}
                  >
                    <Col
                      span={width > 576 ? 14 : 24}
                      className="d-flex justify-content-start align-items-center text-left"
                    >
                      {keyword.name}
                    </Col>
                    <Col
                      span={width > 576 ? 9 : 24}
                      className="d-flex justify-content-end align-items-center"
                      style={{ gap: "0.5rem" }}
                    >
                      <Switch
                        defaultValue={keyword.isActive}
                        checkedChildren={t("switch.on")}
                        unCheckedChildren={t("switch.off")}
                        loading={loading && loadingId === keyword?.id}
                        onClick={() => {
                          setLoading(true);
                          setLoadingId(keyword?.id);
                        }}
                        onChange={() => {
                          dispatch(
                            RequestAppAction.putAutoresponderKeyword({
                              data: {
                                isActive: !keyword.isActive,
                              },
                              id: keyword?.id,
                              cbSuccess: () => {
                                dispatch(
                                  RequestAppAction.fetchAutoresponderKeyword(
                                    {
                                      id: currentDid,
                                      cbFailure: (e: string) => {
                                        notification.error({
                                          message: e,
                                        });
                                      },
                                    }
                                  )
                                );
                              },
                              cbFailure: (e: string) => {
                                notification.error({
                                  message: e ?? t("notification.getAutoResponderKeywordFail")
                                });
                              },
                            })
                          );
                        }}
                        checked={keyword?.isActive}
                      />
                      <Button
                        icon={<EditOutlined />}
                        style={{ borderRadius: 50 }}
                        onClick={() => {
                          setSelectedKeyWord(keyword);
                          showModal();
                        }}
                      />
                      <Popconfirm
                        title="Delete the task"
                        description="Are you sure to delete?"
                        onConfirm={() => confirmDelete(keyword.id)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button
                          icon={<DeleteOutlined />}
                          style={{ borderRadius: 50 }}
                        />
                      </Popconfirm>
                    </Col>
                  </Row>
                </Card>
              ))
            ) : (
              <Space
                style={{ height: "20rem" }}
                className="d-flex align-items-center justify-content-center"
              >
                <div>
                  <div style={{ textAlign: "center" }}>
                    <Text
                      style={{
                        display: "flex",
                        alignItems: "center",
                        overflowY: "auto",
                        justifyContent: "center",
                        paddingTop: "4rem",
                        color: "#ccc",
                        fontSize: "1rem",
                        fontWeight: 300,
                      }}
                    >
                      {t("common.noListKeyword")}
                    </Text>
                  </div>
                </div>
              </Space>
            )}
          </Col>
        </Row>
      </Card>

      <Row className="d-flex justify-content-center keywords-card-button">
        <Col span={24} className="d-flex justify-content-center">
          <Space>
            <Button
              style={{ marginTop: "2rem" }}
              type="primary"
              icon={<PlusCircleOutlined />}
              onClick={() => {
                setSelectedKeyWord({});
                showModal();
              }}
            >
              {t("button.keywords")}
            </Button>
          </Space>
        </Col>
      </Row>
      {/*  Modal for keyword reponder */}
      <div className="keyword-modal">
        <Modal
          title={
            <Title level={4} className="font-size-20 font-weight-500">
              {t("modalHeading.newKeywordResponder")}
            </Title>
          }
          open={open}
          centered
          onOk={handleSubmit}
          onCancel={hideModal}
          okText={t("button.save")}
          cancelText={t("button.cancel")}
          style={{ height: "450px", width: "400" }}
          className="keywrods-modal"
        >
          <Form form={form}>
            <Space
              direction="vertical"
              size={6}
              style={{ width: "100%", padding: "1.25rem 0rem 0.6rem 0rem" }}
            >
              <Form.Item
                label={
                  <Title level={5} className="font-size-16 font-weight-500">
                    {t("fields.keywordName")}
                  </Title>
                }
                hasFeedback
                name="name"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Please enter a name",
                  },
                ]}
              >
                <Input placeholder="Name of new template" allowClear />
              </Form.Item>
              <Title level={5} className="font-size-16 font-weight-500">
                {" "}
                {t("heading.keywords")}
              </Title>
              <Form.Item
                label="Keywords"
                name="keywordsArray"
                style={{ margin: 0 }}
                rules={[
                  {
                    required: keywordsArray?.length > 0 ? false : true,
                    message: "Please enter at least one keyword",
                  },
                ]}
              >
                <Space wrap>
                  {keywordsArray &&
                    keywordsArray.map((keyword, index) => (
                      <Tag
                        key={index}
                        closable
                        onClose={() => handleTagClose(keyword)}
                        className="tag"
                      >
                        {keyword}
                      </Tag>
                    ))}
                  <Input
                    placeholder="Press Enter to Add"
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleInputKeyDown}
                    style={{
                      width: "100%",
                      border: "1px solid #d9d9d9",
                      borderRadius: "4px",
                      padding: "5px 8px",
                    }}
                  />
                </Space>
              </Form.Item>
              <Space style={{ marginBottom: "1rem" }}>
                <Text className="font-size-12 font-weight-400">
                  {" "}
                  <ExclamationCircleOutlined className="pr-3" />{" "}
                  {t(
                    "common.pressEnterAfterEachNewKeywordToAddMultipleKeywords"
                  )}
                </Text>
              </Space>

              <Space direction="vertical" style={{ width: "100%" }}>
                <Title level={5} className="font-size-16 font-weight-500">
                  {t("heading.addMessage")}
                </Title>

                <div>
                  <div
                    style={{ width: "100%" }}
                    className={`counter-text ${width > 310 ? "d-flex" : "d-flex-column align-items-start"} font-size-14`}
                  >
                    <div>
                      {t("sideBar.chars")} {characters}/{MSG_CHAR_LIMIT}
                    </div>
                    <div className={width > 310 ? "pl-3" : "pl-0"}>
                      {t("sideBar.msgUnits")} {units}/{MSG_UNIT_LIMIT}
                    </div>
                  </div>
                  <Form.Item
                    name="content"
                    rules={[
                      {
                        required: true,
                        message: "Please enter a content",
                      },
                    ]}
                  >
                    <Input.TextArea
                      rows={4}
                      placeholder="Enter template content here..."
                      onKeyDown={() => {
                        if (units > MSG_UNIT_LIMIT) return;
                      }}
                      value={selectedKeyWord?.keywords ? editValue : inputValue}
                      onChange={(e) => {
                        if (selectedKeyWord?.keywords) {
                          setEditValue(e.target.value);
                        } else {
                          setInputValue(e.target.value);
                        }
                      }}
                      style={{ resize: "none" }}
                    />
                  </Form.Item>
                </div>
              </Space>
            </Space>
          </Form>
        </Modal>
      </div>
    </SettingsLayout>
  );
};

export default KeyWords;
