import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Form, Input, Tooltip, Typography } from "antd";
import RequestAppAction from "src/store/slices/appActions";
import { USERNAMEREGEX } from "src/constants/common";
import { formatNumberStringEN } from "src/utils/format";
import { capitalizeFirstLetter } from "src/utils/helper-functions";
import EditNumberBlock from "./EditNumberBlock";
import styles from "src/pages/subscribe/subscribe.module.scss";

const { Text } = Typography;

interface IEditUserBlock {
  form: any;
  n: number;
  numUsers: number;
  numUsersDids: any;
  initialLoad: boolean;
}

const EditUserBlock = ({
  form,
  n,
  numUsers,
  numUsersDids,
  initialLoad,
}: IEditUserBlock) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [numDids, setNumDids] = useState<number>(1);

  const userRequiredFields = {
    username: true,
    contactName: true,
    email: true,
  };

  useEffect(() => {
    setNumDids(numUsersDids[n] || 1);
  }, [numUsers]);

  const checkAvailability = (username: string) => {
    return new Promise ((resolve, reject) => {
      dispatch(
        RequestAppAction.availabilityUsername({
          username: username,
          cbSuccess: (res: any) => {
            if (res?.statusCode === 200) {
              resolve("");
            }
            resolve("");
          },
          cbFailure: (e: string) => {
            reject(new Error(e));
          },
        })
      );
    })
  };

  const validateUserName = async () => {
    const username = form.getFieldValue(`user_${n}_username`);
    const trimmedValue = username ? username.trim() : "";

    if (!trimmedValue || trimmedValue.length === 0) {
      return Promise.resolve();
    }

    if (trimmedValue.length < 6 || trimmedValue.length > 20) {
      return Promise.reject(new Error(t("fields.usernameLength")));
    }

    if (!USERNAMEREGEX.test(trimmedValue)) {
      return Promise.reject(new Error(t("fields.usernameRestriction")));
    }

    return checkAvailability(trimmedValue).then(() => {
      for (let i = 1; i <= numUsers; i++) {
        if (i !== n) {
          const value = form.getFieldValue(`user_${i}_username`);
          if (value === trimmedValue) {
            return Promise.reject(new Error(
              t(
                "fields.usernameAlreadyInUse",
                { number: capitalizeFirstLetter(formatNumberStringEN(i)) }
              )
            ));
          }
        }
      }
      return Promise.resolve();
    }).catch((error: any) => {
      return Promise.reject(
        new Error(error?.message || t("fields.usernameCannotUse"))
      );
    });
  };

  const reviewInputBlock = (
    label: string,
    rules: any[],
    required: boolean,
    name: string,
    placeholder: string = "",
    disabled: boolean = false,
    tooltipTitle: string = ""
  ) => {
    return (
      <div className={styles.review_block}>
        <Text className={styles.review_block_label}>
          {capitalizeFirstLetter(formatNumberStringEN(n))}
          {" "}User - {label}
        </Text>
        {tooltipTitle !== "" ? (
          <Tooltip
            trigger={["hover", "click"]}
            title={tooltipTitle}
          >
            <Form.Item
              name={name}
              rules={rules}
              className={
                `${styles.sign_up_form_item} ${
                  required ? styles.required : ""
                }`
              }
            >
              <Input
                type="text"
                placeholder={placeholder}
                className={styles.sign_up_form_input}
                disabled={disabled}
              />
            </Form.Item>
          </Tooltip>
        ) : (
          <Form.Item
            name={name}
            rules={rules}
            className={
              `${styles.sign_up_form_item} ${
                required ? styles.required : ""
              }`
            }
          >
            <Input
              type="text"
              placeholder={placeholder}
              className={styles.sign_up_form_input}
              disabled={disabled}
            />
          </Form.Item>
        )}
      </div>
    );
  };

  return (
    <div
      className={
        `${styles.review_section_sub} ${styles.review_section_sub_edit}`
      }
    >
      {reviewInputBlock(
        t("fields.username"),
        [
          {
            required: userRequiredFields.username,
            message: t("fields.usernameRequired")
          },
          { validator: validateUserName },
        ],
        userRequiredFields.username,
        `user_${n}_username`,
        t("fields.createUsername")
      )}
      {reviewInputBlock(
        t("subscribeStep5.contactName"),
        [
          {
            required: userRequiredFields.contactName,
            message: t("fields.fullContactNameRequired")
          },
        ],
        userRequiredFields.contactName,
        `user_${n}_contactName`,
        t("fields.fullContactName")
        // initialValue={n === 1 && name ? name : ""}
      )}
      {reviewInputBlock(
        t("fields.emailAddress"),
        [
          {
            required: userRequiredFields.email,
            message: t("fields.emailRequired")
          },
          { type: "email", message: t("fields.emailError") },
        ],
        userRequiredFields.email,
        `user_${n}_email`,
        t("fields.emailAddress"),
        n === 1,
        n === 1 ? t("message.cannotChangeEmail") : ""
        // initialValue={n === 1 && name ? name : ""}
      )}

      {[...Array(numDids)].map((_, j: number) => (
        <EditNumberBlock
          key={`user-${n}-did-${j + 1}`}
          form={form}
          i={n}
          j={j + 1}
          numDids={numDids}
          numberType={form.getFieldValue(`user_${n}_did_${j + 1}_numberType`)}
          initialLoad={initialLoad}
        />
      ))}
    </div>
  );
};

export default EditUserBlock;
