import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {
  Button as Btn,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Typography,
  notification,
} from "antd";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Colors from "src/themes/colors";
import { MSG_CHAR_LIMIT, MSG_UNIT_LIMIT } from "src/constants/common";
import { dateFormat, dateToUtc } from "src/constants/functions";
import { calculateUnitCharacters } from "src/utils/helper-functions";
import useWindowDimensions from "src/hooks/useWindowDimensions";
import { getCurrentDidId } from "src/store/selectors/features/currentDid";
import { getHolidayResponderData } from "src/store/selectors/features/holidayResponder";
import RequestAppAction from "src/store/slices/appActions";
import { clearVacation } from "src/store/slices/features/holidayResponder";
import AutoResponderLayout from "src/layouts/auto-responder";
import AutoResponder from "src/components/autoresponder";
import Button from "src/components/button";
import "../../setting.scss";
import "./vacation.scss";

const { Title, Text } = Typography;
const { RangePicker } = DatePicker;

const Vacation: React.FC = () => {
  const { width } = useWindowDimensions();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = useForm();

  const currentDid = useSelector(getCurrentDidId);
  const holidaysData: any = useSelector(getHolidayResponderData);

  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editId, setEditId] = useState<{ id: string } | null>(null);
  const [editData, setEditData] = useState<any>(null);
  const [inputValue, setInputValue] = useState<string>("");
  const [editValue, setEditValue] = useState<string>("");
  const [units, setUnits] = useState<number>(0);
  const [characters, setCharacters] = useState<number>(0);

  useEffect(() => {
    return () => {
      dispatch(clearVacation());
    };
  }, []);

  useEffect(() => {
    if (!open) {
      setInputValue("");
      setUnits(0);
      setCharacters(0);
    }
  }, [open]);

  useEffect(() => {
    if (edit) {
      setEditValue(editData?.content || "");
      const { valueUnits, valueChars } = calculateUnitCharacters(editData?.content || "");
      setUnits(valueUnits);
      setCharacters(valueChars);
    } else {
      setEditValue("");
      setUnits(0);
      setCharacters(0);
    }
  }, [edit]);

  useEffect(() => {
    const { valueUnits, valueChars } = calculateUnitCharacters(inputValue);
    setUnits(valueUnits);
    setCharacters(valueChars);
  }, [inputValue]);

  useEffect(() => {
    const { valueUnits, valueChars } = calculateUnitCharacters(editValue);
    setUnits(valueUnits);
    setCharacters(valueChars);
  }, [editValue]);

  const showModal = () => {
    setOpen(true);
  };

  const hideModal = () => {
    setOpen(false);
    setEdit(false);
    setEditData(null);
    setEditId(null);
    form.resetFields();
  };

  const onFinish = (val: any) => {
    const param = {
      name: val?.name,
      content: val?.content,
      fromDate: val?.range[0]?.$d,
      toDate: val?.range[1]?.$d,
    };

    if (edit) {
      const obj: {
        name?: string;
        content?: string;
        fromDate?: any;
        toDate?: any;
      } = {};
      if (editData?.name !== val?.name) {
        obj["name"] = val?.name?.trim();
      }

      if (editData?.content !== val?.content) {
        obj["content"] = val?.content?.trim();
      }
      const from = new Date(val?.range[0]?.$d);
      const to = new Date(val?.range[1]?.$d);

      if (editData?.toDate?.split("T")[0] !== dateFormat(to)) {
        obj["fromDate"] = dateToUtc(from);
        obj["toDate"] = dateToUtc(to);
      }

      if (editData?.fromDate?.split("T")[0] !== dateFormat(from)) {
        obj["fromDate"] = dateToUtc(from);
        obj["toDate"] = dateToUtc(to);
      }

      if (Object.keys(obj).length > 0) {
        dispatch(
          RequestAppAction.updateHolidayResponder({
            data: obj,
            id: editId?.id,
            cbSuccess: () => {
              notification.success({ message: t("notification.success") });
              dispatch(
                RequestAppAction.getHolidayResponder({ id: currentDid })
              );
              hideModal();
            },
            cbFailure: (e: string) => {
              notification.error({
                message: e ?? t("notification.editAutoResponderVacationFail")
              });
            },
          })
        );
      } else {
        hideModal();
      }
    } else {
      dispatch(
        RequestAppAction.postHolidayResponder({
          data: param,
          id: currentDid,
          cbSuccess: () => {
            notification.success({ message: t("notification.success") });
            dispatch(RequestAppAction.getHolidayResponder({ id: currentDid }));
            hideModal();
          },
          cbFailure: (e: string) => {
            notification.error({
              message: e ?? t("notification.addAutoResponderVacationFail")
            });
            hideModal();
          },
        })
      );
    }
  };

  useEffect(() => {
    if (currentDid) {
      dispatch(
        RequestAppAction.getHolidayResponder({
          id: currentDid,
          cbFailure: (e: String) => {
            notification.error({
              message: e ?? t("notification.getAutoResponderVacationFail")
            });
          },
        })
      );
    }
  }, [currentDid]);

  const deleteHolidayResponder = (id: string | number) => {
    Modal.confirm({
      title: t("button.delete"),
      content: t("modal.confirmDelete"),
      okType: "danger",
      icon: null,
      className: "p-0",
      okButtonProps: {
        style: { backgroundColor: Colors.red, color: Colors.white },
      },
      style: { borderRadius: 10 },
      width: "30%",

      onOk: () => {
        dispatch(
          RequestAppAction.deleteHolidayResponder({
            id: id,
            cbSuccess: () => {
              notification.success({ message: t("notification.success") });
              dispatch(
                RequestAppAction.getHolidayResponder({ id: currentDid })
              );
            },
          })
        );
      },
    });
  };

  const updateHolidayResponder = (val: any) => {
    setEdit(true);
    setEditData(val);

    form.setFieldsValue({
      name: val?.name,
      content: val?.content,
      range: [
        dayjs(val?.fromDate?.split("T")[0]),
        dayjs(val?.toDate?.split("T")[0]),
      ],
    });
    setEditId(val);
    showModal();
  };

  const disabledDateTime = (current: any) => {
    return current && current < dayjs().startOf("day");
  };

  return (
    <AutoResponderLayout classname="vacation-page">
      <AutoResponder />
      <Col className="vacation-box">
        <div className="vacation-card">
          {Array.isArray(holidaysData) && holidaysData.length > 0 ? (
            holidaysData?.map((i: any) => (
              <Row className="d-flex justify-content-center">
                <Col span={24}>
                  <Card className="vacation-card-shadow">
                    <Space className="d-flex align-items-center justify-content-between">
                      <Title level={5}>
                        {" "}
                        {i?.name}{" "}
                        <Text className="pl-4 font-size-12 font-weight-300">
                          {i?.fromDate?.split("T")[0]} /{" "}
                          {i?.toDate?.split("T")[0]}
                        </Text>{" "}
                      </Title>
                      <Space size={20}>
                        <Btn
                          icon={<EditOutlined />}
                          style={{ borderRadius: 50 }}
                          onClick={() => updateHolidayResponder(i)}
                        />
                        <Btn
                          onClick={() => deleteHolidayResponder(i?.id)}
                          icon={<DeleteOutlined />}
                          style={{ borderRadius: 50 }}
                        />
                      </Space>
                    </Space>
                    <Space className="font-size-12 font-weight-300">
                      {i?.content}
                    </Space>
                  </Card>
                </Col>
              </Row>
            ))
          ) : (
            <Space
              style={{ height: "10rem" }}
              className="d-flex align-items-center justify-content-center"
            >
              <div>
                <div style={{ textAlign: "center" }}>
                  <Text
                    style={{
                      display: "flex",
                      alignItems: "center",
                      overflowY: "auto",
                      justifyContent: "center",
                      paddingTop: "4rem",
                      color: "#ccc",
                      fontSize: "1rem",
                      fontWeight: 300,
                    }}
                  >
                    {t("common.noListVacation")}
                  </Text>
                </div>
              </div>
            </Space>
          )}
        </div>
      </Col>
      <Col span={24}>
        <Row className="d-flex justify-content-center vacation-card-button">
          <Col span={24} className="d-flex justify-content-center">
            <Space>
              <Btn
                style={{ marginTop: "2rem" }}
                type="primary"
                icon={<PlusCircleOutlined />}
                onClick={showModal}
              >
                {t("button.newHolidayResponder")}
              </Btn>
            </Space>
          </Col>
        </Row>
      </Col>

      <Modal
        title={
          <Title level={4} className="font-size-20 font-weight-500">
            {" "}
            {t("modalHeading.vacationAutoresponderSettings")}
          </Title>
        }
        open={open}
        closable={true}
        destroyOnClose
        footer={false}
        closeIcon={<CloseOutlined onClick={hideModal} />}
      >
        <Form form={form} onFinish={onFinish} name="holidayForm">
          <div className="pt-5 pb-5">
            <Space direction="vertical" style={{ width: "100%" }}>
              <Form.Item
                rules={[{ required: true, message: t("fields.nameError") }]}
                name="name"
              >
                <Input
                  className="pt-4"
                  placeholder={t("common.vacationName")}
                />
              </Form.Item>
              <Space direction="vertical" size={2}>
                <Title level={5} className="font-size-16 font-weight-500">
                  {t("heading.setAutoresponderDate")}{" "}
                </Title>
                <Form.Item
                  rules={[
                    { required: true, message: t("fields.rangeError") },
                  ]}
                  name="range"
                >
                  <RangePicker disabledDate={disabledDateTime} />
                </Form.Item>
              </Space>
              <div>
                <div
                  style={{ width: "100%" }}
                  className={`counter-text ${width > 310 ? "d-flex" : "d-flex-column align-items-start"} font-size-14`}
                >
                  <div>
                    {t("sideBar.chars")} {characters}/{MSG_CHAR_LIMIT}
                  </div>
                  <div className={width > 310 ? "pl-3" : "pl-0"}>
                    {t("sideBar.msgUnits")} {units}/{MSG_UNIT_LIMIT}
                  </div>
                </div>
                <Form.Item
                  rules={[
                    { required: true, message: t("fields.contentError") },
                  ]}
                  name="content"
                >
                  <Input.TextArea
                    rows={4}
                    placeholder="Enter template content here..."
                    onKeyDown={() => {
                      if (units > MSG_UNIT_LIMIT) return;
                    }}
                    value={edit ? editValue : inputValue}
                    onChange={(e) => {
                      if (edit) {
                        setEditValue(e.target.value);
                      } else {
                        setInputValue(e.target.value);
                      }
                    }}
                    style={{ resize: "none" }}
                  />
                </Form.Item>
              </div>
              <div className={"modal_btn_div"}>
                <div className="btn_margin">
                  <Button
                    btnClass="outlined_btn"
                    onBtnClick={hideModal}
                    buttonType="button"
                    title={t("button.cancel")}
                  />
                </div>
                <Button buttonType="submit" title={t("button.save")} />
              </div>
            </Space>
          </div>
        </Form>
      </Modal>
    </AutoResponderLayout>
  );
};

export default Vacation;
