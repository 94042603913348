import {
  CloseCircleFilled,
  CloseOutlined,
  MailOutlined,
  PhoneOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Button,
  Flex,
  Form,
  Input,
  Modal,
  Typography,
  notification,
} from "antd";
import { useForm } from "antd/es/form/Form";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { PhoneNumberRegex } from "src/constants/common";
import { getCurrentDidId } from "src/store/selectors/features/currentDid";
import RequestAppAction from "src/store/slices/appActions";
const { Title } = Typography;

export const NewContactModal = forwardRef(({}, ref) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [form] = useForm();
  const dispatch = useDispatch();
  const did = useSelector(getCurrentDidId);
  const [state, setState] = useState("");

  useImperativeHandle(ref, () => ({
    closeModal: () => {
      setShow(false);
      form.resetFields();
    },
    openModal: () => {
      setShow(true);
    },
  }));

  const validatePhoneNumber = () => {
    const phoneNumber = form.getFieldValue("phoneNumber");

    if (
      phoneNumber &&
      phoneNumber.length > 0 &&
      !PhoneNumberRegex.test(phoneNumber)
    ) {
      return Promise.reject(new Error(t("notification.numberMustBeValid")));
    }

    return Promise.resolve();
  };

  const onFinish = () => {
    form
      .validateFields()
      .then((res) => {
        const num = res?.phoneNumber?.trim()?.replace(/[\s-]/g, "");
        const param: {
          firstName: any;
          lastName: any;
          phoneNumber: any;
          companyName: any;
          email?: any;
          isBlocked: boolean;
        } = {
          firstName: res?.firstName?.trim(),
          lastName: res?.lastName?.trim(),
          phoneNumber:
            num?.startsWith("+1") && num?.length === 12
              ? num
              : num?.startsWith("+") && num?.length === 11
              ? "+" + res?.phoneNumber?.trim()
              : "+1" + res?.phoneNumber?.trim(),
          companyName: res?.companyName?.trim(),
          isBlocked: false,
        };
        if (res?.email?.length > 0) {
          param["email"] = res?.email?.trim();
        }
        dispatch(
          RequestAppAction.addNewContact({
            id: did,
            data: param,
            cbSuccess: () => {
              dispatch(RequestAppAction.fetchContacts({ id: did }));
              notification.success({ message: t("notification.success") });
              setShow(false);
              form.resetFields();
            },
            cbFailure: (e: string) => {
              notification.error({
                message: e ?? t("notification.addContactFail")
              });
            },
          })
        );
      })
      .catch(() => {
        return;
      });
  };

  useEffect(() => {
    if (state) {
      const handler = setTimeout(() => {
        if (!PhoneNumberRegex.test(state)) {
          form.setFields([
            {
              name: "phoneNumber",
              errors: [t("notification.numberMustBeValid")],
            },
          ]);
        }
      }, 300);

      return () => {
        clearTimeout(handler);
      };
    }
  }, [state]);

  return (
    <Modal
      centered
      destroyOnClose
      open={show}
      okButtonProps={{ htmlType: "submit" }}
      closeIcon={
        <CloseOutlined
          onClick={() => {
            setShow(false);
            form.resetFields();
          }}
        />
      }
      title={
        <Title className="font-size-24 font-weight-500">
          {t("modalHeading.newContact")}
        </Title>
      }
      className="new-contact-modal"
      footer={[
        <Button
          key="submit"
          onClick={() => (setShow(false), form.resetFields())}
        >
          {t("button.cancel")}
        </Button>,
        <Button key="submit" type="primary" onClick={onFinish}>
          {t("button.save")}
        </Button>,
      ]}
    >
      <Form form={form} name="addNewContact">
        <Flex vertical gap={10} style={{ padding: "1.3rem 0rem" }}>
          <Form.Item
            rules={[{ required: true, message: t("fields.nameError") }]}
            name={"firstName"}
          >
            <Input
              placeholder="First name"
              addonBefore={<span style={{ color: "red" }}>*</span>}
              prefix={<UserOutlined className="site-form-item-icon" />}
              suffix={
                <CloseCircleFilled
                  onClick={() => {
                    form.setFieldsValue({ firstName: "" });
                  }}
                  style={{ color: "rgba(0,0,0,.45)" }}
                />
              }
            />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: t("fields.nameError") }]}
            name={"lastName"}
          >
            <Input
              placeholder="Last name"
              addonBefore={<span style={{ color: "red" }}>*</span>}
              prefix={<UserOutlined className="pl-2 site-form-item-icon" />}
              suffix={
                <CloseCircleFilled
                  onClick={() => {
                    form.setFieldsValue({ lastName: "" });
                  }}
                  style={{ color: "rgba(0,0,0,.45)" }}
                />
              }
            />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: t("fields.numberError") },
              { validator: validatePhoneNumber }
            ]}
            name={"phoneNumber"}
          >
            <Input
              onChange={(e) => {
                setState(e.target.value);
              }}
              // onKeyDown={(e) => {
              //   const key = e.key;
              //   if (
              //     !/[0-9]/.test(key) &&
              //     key !== "ArrowLeft" &&
              //     key !== "ArrowRight" &&
              //     key !== "+" &&
              //     key !== "Backspace"
              //   ) {
              //     e.preventDefault();
              //   }
              // }}
              placeholder="Phone number"
              addonBefore={<span style={{ color: "red" }}>*</span>}
              prefix={<PhoneOutlined className="pl-2 site-form-item-icon" />}
              suffix={
                <CloseCircleFilled
                  onClick={() => {
                    form.setFieldsValue({ phoneNumber: "" });
                  }}
                  style={{ color: "rgba(0,0,0,.45)" }}
                />
              }
            />
          </Form.Item>
          <Form.Item
            rules={[{ type: "email", message: t("fields.emailError") }]}
            name={"email"}
          >
            <Input
              placeholder="Email address"
              prefix={<MailOutlined className="pl-2 site-form-item-icon" />}
              suffix={
                <CloseCircleFilled
                  onClick={() => {
                    form.setFieldsValue({ email: "" });
                  }}
                  style={{ color: "rgba(0,0,0,.45)" }}
                />
              }
            />
          </Form.Item>
          <Form.Item name={"companyName"}>
            <Input placeholder="Company name" />
          </Form.Item>
        </Flex>
      </Form>
    </Modal>
  );
});
