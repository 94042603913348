import { AxiosResponse } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";
import {
  getLoggedInUserFailure,
  getLoggedInUserSuccess,
} from "../slices/features/LoggedInUser";
import { RequestTypes } from "../types";
import { Status } from "src/constants/common";

const appservice = new AppService();

function* fetchLoggedInUserSaga(action: any) {
  const { payload } = action;

  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      appservice.userDetails,
      baseUrl
    );

    // If pending account
    if (
      response?.data &&
      (
        response?.data?.companies?.status !== Status.ACTIVE ||
        response?.data?.status !== Status.ACTIVE
      )
    ) {
      response.data["isPending"] = true;
    }

    // If free stripe product
    if (
      response?.data?.companies &&
      response?.data?.plan?.stripeProductId &&
      response?.data?.plan?.monthlyRate !== null &&
      response?.data?.plan?.monthlyRate !== undefined &&
      response?.data?.plan?.monthlyRate !== ""
    ) {
      const monthlyRate = response.data.plan.monthlyRate;
      if (
        (monthlyRate === "0.00" || monthlyRate === 0 || monthlyRate === "0") &&
        !response.data.companies.billedExternally
      ) {
        response.data["isInboundOnly"] = true;
      }
    }

    yield put(getLoggedInUserSuccess({ ...response }));

    payload?.cbSuccess && payload?.cbSuccess();
  } catch (error: any) {
    const { statusCode, statusText } = error;

    const message = error?.data?.errors?.message;

    payload?.cbFailure && payload?.cbFailure(message);

    yield put(getLoggedInUserFailure({ statusCode, statusText }));
  }
}

export function* watchfetchLoggedInUser() {
  yield takeLatest(
    RequestTypes.GET_LOGGEDIN_USER_REQUEST,
    fetchLoggedInUserSaga
  );
}
