import { DefaultOptionType } from "antd/es/select";

export const PAGINATION = {
  page: 1,
  limit: 10,
};

export const MSG_CHAR_LIMIT = 160;
export const MSG_UNIT_LIMIT = 10;

export const FILE_MAX_SIZE = 5 * 1024 * 1024; // 5MB

export const TIMEOUT = 15000;

export const PhoneNumberRegex = /^\+?1?\s?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;

// export const USERNAMEREGEX = /^[a-zA-Z0-9._]{6,20}$/;
export const USERNAMEREGEX = /^[a-zA-Z0-9._]*$/;

export const PASSWORDREGEX =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,30}$/;

export const CAPOSTALCODEREGEX = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;

export const USPOSTALCODEREGEX = /^\d{5}(?:[-\s]\d{4})?$/;

export const PHONENUMBERREGEX = /^[0-9]*$/;

export const URLREGEX =
  /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

export const EMAILREGEX =
  /(?<!<a[^>]*>)(?<!:\/\/)([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)(?!<\/a>)/gi;

export const countryCodeToCurrency: any = {
  "CA": "CAD",
  "US": "USD",
};

export const Status = {
  ACTIVE: 'ACTIVE',
  PENDING: 'PENDING',
  INACTIVE: 'INACTIVE',
}

export const InvoiceStatus = {
  PAID: 'PAID',
  PARTIAL: 'PARTIALLY PAID',
  NOTPAID: 'NOT PAID',
}

export const provinces: any = {
  "AB": "Alberta",
  "BC": "British Columbia",
  "MB": "Manitoba",
  "NB": "New Brunswick",
  "NL": "Newfoundland and Labrador",
  "NS": "Nova Scotia",
  "NT": "Northwest Territories",
  "NU": "Nunavut",
  "ON": "Ontario",
  "PE": "Prince Edward Island",
  "QC": "Quebec",
  "SK": "Saskatchewan",
  "YT": "Yukon",
};

export const states: any = {
  "AL": "Alabama",
  "AK": "Alaska",
  "AS": "American samoa",
  "AZ": "Arizona",
  "AR": "Arkansas",
  "CA": "California",
  "CO": "Colorado",
  "CT": "Connecticut",
  "DE": "Delaware",
  "DC": "District of columbia",
  "FM": "Federated states of micronesia",
  "FL": "Florida",
  "GA": "Georgia",
  "GU": "Guam",
  "HI": "Hawaii",
  "ID": "Idaho",
  "IL": "Illinois",
  "IN": "Indiana",
  "IA": "Iowa",
  "KS": "Kansas",
  "KY": "Kentucky",
  "LA": "Louisiana",
  "ME": "Maine",
  "MH": "Marshall islands",
  "MD": "Maryland",
  "MA": "Massachusetts",
  "MI": "Michigan",
  "MN": "Minnesota",
  "MS": "Mississippi",
  "MO": "Missouri",
  "MT": "Montana",
  "NE": "Nebraska",
  "NV": "Nevada",
  "NH": "New hampshire",
  "NJ": "New jersey",
  "NM": "New mexico",
  "NY": "New york",
  "NC": "North carolina",
  "ND": "North dakota",
  "MP": "Northern mariana islands",
  "OH": "Ohio",
  "OK": "Oklahoma",
  "OR": "Oregon",
  "PW": "Palau",
  "PA": "Pennsylvania",
  "PR": "Puerto rico",
  "RI": "Rhode island",
  "SC": "South carolina",
  "SD": "South dakota",
  "TN": "Tennessee",
  "TX": "Texas",
  "UT": "Utah",
  "VT": "Vermont",
  "VI": "Virgin islands",
  "VA": "Virginia",
  "WA": "Washington",
  "WV": "West virginia",
  "WI": "Wisconsin",
  "WY": "Wyoming"
};

export const selectFilterOption = (input: string, option: DefaultOptionType | undefined) => {
  return (typeof option?.label === 'string'
    ? option.label.toLowerCase()
    : ''
  ).includes(input.toLowerCase());
};

export const axiosHeaders = {
  "Content-Type": "application/json",
  "App-Type": "CONSUMER",
  "Cache-Control": "no-cache",
  "Access-Control-Allow-Origin": "*",
};
