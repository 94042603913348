import { combineReducers } from "redux";
import { LoggedInUserReducer } from "./LoggedInUser";
import { appFeatureReducer } from "./app";
import { LoginReducer } from "./auth";
import { autoresponderReducer } from "./autoResponder";
import { AutoresponderKeywordReducer } from "./autoresponderKeyword";
import { contactBulkReducer } from "./bulkMessageContacts";
import { changePasswordReducer } from "./changePassword";
import { ContactReducer } from "./contacts";
import { conversationsReducer } from "./conversations";
import { conversationReducer } from "./conversationsById";
import { createGroupsReducer } from "./createGroup";
import { currentDidReducer } from "./currentDid";
import { ContactUserReducer } from "./currentUserContact";
import { ConversationUserReducer } from "./currentUserConversation";
import { deleteReducer } from "./deleteContact";
import { DeleteGroupContactReducer } from "./deleteContactGroup";
import { didNotificationReducer } from "./didNotification";
import { DidReducer } from "./fetchDids";
import { EventsReducer } from "./fetchEvents";
import { groupContactsReducer } from "./groupContacts";
import { groupMessagesReducer } from "./groupMessages";
import { groupsReducer } from "./groups";
import { holidayResponderReducer } from "./holidayResponder";
import { PostMessageReducer } from "./message";
import { addNewContactReducer } from "./newContact";
import { NotesReducer } from "./notes";
import { PlanReducer } from "./plan";
import { PostAutoresponderKeywordReducer } from "./postAutoresponderKeyword";
import { PostEventsReducer } from "./postEvents";
import { PostMultipleMessagesReducer } from "./postMultipleMessage";
import { PostNotesReducer } from "./postNote";
import { PostPreferenceReducer } from "./postPreference";
import { PostScheduleMessageReducer } from "./postScheduleMessage";
import { PostTemplateReducer } from "./postTemplate";
import { PreferenceReducer } from "./preference";
import { resetPasswordReducer } from "./reset-password";
import { ScheduleMessageReducer } from "./scheduleMessages";
import { selectedGroupReducer } from "./selectedGroup";
import { signoutReducer } from "./signout";
import { TemplateReducer } from "./template";
import { uploadContactsReducer } from "./uploadContacts";
import { UploadFilesReducer } from "./uploadFiles";
import { UserReducer } from "./user";
import { verifyTokenReducer } from "./verifyToken";
import { workingDaysReducer } from "./workingDays";
import { NewMessageNotificationReducer } from "./newMessageNotification";
import { ReadMessageStatusReducer } from "./readMessageStatus";
import { AllConversationsReducer } from "./allConversations";
import { stripeCustomerReducer } from "./stripeCustomer";
import { stripePaymentMethodReducer } from "./stripePaymentMethod";
import { stripeProductDbReducer } from "./stripeProductDb";
import { stripeProductReducer } from "./stripeProduct";
import { stripeSubscriptionReducer } from "./stripeSubscription";
import { createCompanyReducer } from "./createCompany";
import { rolesReducer } from "./roles";
import { availabilityReducer } from "./availability";

const featuresReducer = combineReducers({
  app: appFeatureReducer,
  login: LoginReducer,
  signout: signoutReducer,
  dids: DidReducer,
  conversation: conversationsReducer,
  conversationsById: conversationReducer,
  contact: ContactReducer,
  notes: NotesReducer,
  postNotes: PostNotesReducer,
  scheduleMessage: ScheduleMessageReducer,
  postScheduleMessage: PostScheduleMessageReducer,
  user: UserReducer,
  template: TemplateReducer,
  currentDid: currentDidReducer,
  postTemplate: PostTemplateReducer,
  preference: PreferenceReducer,
  postPreference: PostPreferenceReducer,
  verifyToken: verifyTokenReducer,
  resetPassword: resetPasswordReducer,
  changePassword: changePasswordReducer,
  holidayResponder: holidayResponderReducer,
  autoResponder: autoresponderReducer,
  workingDays: workingDaysReducer,
  autoresponderKeyword: AutoresponderKeywordReducer,
  postAutoresponderKeyword: PostAutoresponderKeywordReducer,
  message: PostMessageReducer,
  newContact: addNewContactReducer,
  postMultipleMessage: PostMultipleMessagesReducer,
  uploadContacts: uploadContactsReducer,
  groups: groupsReducer,
  selectedGroup: selectedGroupReducer,
  groupContacts: groupContactsReducer,
  groupMessages: groupMessagesReducer,
  createGroup: createGroupsReducer,
  deleteGroupContact: DeleteGroupContactReducer,
  conversationUser: ConversationUserReducer,
  contactUser: ContactUserReducer,
  conversationContactDelete: deleteReducer,
  uploadFiles: UploadFilesReducer,
  events: EventsReducer,
  postEvent: PostEventsReducer,
  LoggedInUser: LoggedInUserReducer,
  contactBulk: contactBulkReducer,
  plans: PlanReducer,
  didNotification: didNotificationReducer,
  newMessageNotification: NewMessageNotificationReducer,
  readMessageStatus: ReadMessageStatusReducer,
  allConversations: AllConversationsReducer,
  stripeCustomer: stripeCustomerReducer,
  stripePaymentMethod: stripePaymentMethodReducer,
  stripeProduct: stripeProductReducer,
  stripeProductDb: stripeProductDbReducer,
  stripeSubscription: stripeSubscriptionReducer,
  createCompany: createCompanyReducer,
  roles: rolesReducer,
  availability: availabilityReducer,
  /**
   * More feature reducers will be added here
   */
});

export { featuresReducer };
