import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { Button, Flex, Form, Input, Select, SelectProps, Typography } from "antd";
import { useForm } from "antd/es/form/Form";
import { allTimezones, useTimezoneSelect } from "react-timezone-select";
import useGeoLocation from "src/hooks/useGeoLocation";
import { CAPOSTALCODEREGEX, provinces, selectFilterOption, states, USPOSTALCODEREGEX } from "src/constants/common";
import { labelStyle } from "src/constants/samples";
import { capitalizeFirstLetter, getOptionsList } from "src/utils/helper-functions";
import { formatNumberStringEN } from "src/utils/format";
import Header from "../Header";
import CreateUserForm from "../CreateUserForm";
import styles from "src/pages/subscribe/subscribe.module.scss";

const { Text } = Typography;

interface IStep3 {
  userData: any;
  isFreePlan: boolean;
  selectedCountry: string | undefined;
  setSelectedCountry: (country: string | undefined) => void;
  signUpUserFormData?: any;
  numUsersDids: any;
  setNumUsersDids: (num: any) => void;
  numberTypes: any;
  setNumberTypes: (numberType: any) => void;
  prevStep: (n?: number) => void;
  onFinish: (value: any) => void;
}

const Step3 = ({
  userData,
  isFreePlan,
  selectedCountry,
  setSelectedCountry,
  signUpUserFormData,
  numUsersDids,
  setNumUsersDids,
  numberTypes,
  setNumberTypes,
  prevStep,
  onFinish
}: IStep3) => {
  const location = useGeoLocation();
  const { t } = useTranslation();
  const [form] = useForm();

  const [numUsers, setNumUsers] = useState<number>(1);
  const [initialLoad, setInitialLoad] = useState<boolean>(true);

  const requiredFields = {
    companyName: true,
    timezone: true,
    howDidYouHear: false,
    country: true,
    province: true,
    state: true,
    city: true,
    postalCode: true,
  };

  const { options: timezoneOptions, parseTimezone } = useTimezoneSelect({
    labelStyle,
    timezones: allTimezones,
  });

  useEffect(() => {
    if (!initialLoad) return;

    if (signUpUserFormData) {
      form.setFieldsValue(signUpUserFormData);

      if (signUpUserFormData.country) {
        setSelectedCountry(signUpUserFormData.country);

        if (signUpUserFormData.country === "CA") {
          form.setFieldsValue({
            province: signUpUserFormData.province || signUpUserFormData.state,
            state: undefined,
          });
        }
      }

      const numUsers = Object.keys(signUpUserFormData).filter((key) =>
        key.startsWith("user_") && key.endsWith("_username")
      ).length;
      setNumUsers(numUsers);

      let newNumberTypes = numberTypes;

      for (let i = 1; i <= numUsers; i++) {
        const numDids = Object.keys(signUpUserFormData).filter((key) =>
          key.startsWith(`user_${i}_did_`) && key.endsWith("_numberType")
        ).length;

        const newNumUsersDids = numUsersDids;
        newNumUsersDids[i] = numDids;
        setNumUsersDids(newNumUsersDids);

        if (numDids && numUsers > 0) {

          for (let j = 1; j <= numDids; j++) {
            newNumberTypes = {
              ...newNumberTypes,
              [`user_${i}_did_${j}_numberType`]: signUpUserFormData[`user_${i}_did_${j}_numberType`]
            }
          }
        }
      }

      setNumberTypes(newNumberTypes);
    }

    setInitialLoad(false);
  }, [signUpUserFormData]);

  const timezoneList = () => {
    const list = [];

    for (let i = 0; i < timezoneOptions.length; i++) {
      list.push({
        key: `timezone-${i}`,
        label: timezoneOptions[i].label,
        value: timezoneOptions[i].value
      });
    }

    return list;
  };

  const timeZoneOptions: SelectProps['options'] = timezoneList();
  const countryOptions: SelectProps['options'] = [
    { key: "CA", label: t("geoLocation.countries.CA"), value: "CA" },
    { key: "US",label: t("geoLocation.countries.US"), value: "US" },
  ];
  const provinceOptions: SelectProps['options'] = getOptionsList(provinces);
  const stateOptions: SelectProps['options'] = getOptionsList(states);

  useEffect(() => {
    if (
      !selectedCountry
      && location?.data?.countryCode
      && countryOptions.find(o => o.value === location.data.countryCode)
    ) {
      setSelectedCountry(location.data.countryCode);
    }
  }, [location]);

  const onChangeCountry = (value: string) => {
    setSelectedCountry(value);
    if (value === "CA") {
      form.resetFields(["state"]);
    } else if (value === "US") {
      form.resetFields(["province"]);
    }
  };

  const validatePostalCode = () => {
    const postalCode = form.getFieldValue("postalCode");
    const trimmedValue = postalCode ? postalCode.trim() : "";

    if (!trimmedValue || trimmedValue.length === 0) {
      return Promise.resolve();
    }

    if (selectedCountry === "CA") {
      if (!CAPOSTALCODEREGEX.test(trimmedValue)) {
        return Promise.reject(new Error(t("fields.postalCodeInvalid")));
      }
    }

    if (selectedCountry === "US") {
      if (!USPOSTALCODEREGEX.test(trimmedValue)) {
        return Promise.reject(new Error(t("fields.zipCodeInvalid")));
      }
    }

    return Promise.resolve();
  };

  const addUser = () => {
    const newNumUsersDids = numUsersDids;
    newNumUsersDids[numUsers + 1] = 1;
    setNumUsersDids(newNumUsersDids);
    setNumUsers(numUsers + 1);
  };

  const deleteUser = (j: number) => {
    const allValues = form.getFieldsValue(true);
    const newNumUsersDids = numUsersDids;

    const newNumberTypes = numberTypes;
    for (let i = j; i <= numUsers; i++) {
      const currKeys = Object.keys(allValues)
        .filter(key => key.startsWith(`user_${i}_`));

      const nextValues = currKeys.reduce((obj: { [key: string]: any }, key: string) => {
        if (i === j) {
          obj[key] = undefined;
          return obj;
        }
        const nextKey = key.replace(`user_${i}_`, `user_${i - 1}_`);
        const nextValue = form.getFieldValue(nextKey); 
        const newKey = key;
        obj[newKey] = nextValue;
        return obj;
      }, {});

      if (i === j) {
        delete newNumUsersDids[i];
      } else {
        newNumUsersDids[i - 1] = newNumUsersDids[i];
      }
      form.setFieldsValue(nextValues);
      const didNames = Object.keys(newNumUsersDids).filter((key) =>
        key.startsWith(`user_${i}_did_`)
        && (key.endsWith("_numberType"))
      )
      for (const didName of didNames) {
        if (i === j) {
          delete newNumberTypes[didName];
        } else {
          const newDidName = didName.replace(`user_${i}_`, `user_${i - 1}_`);
          newNumberTypes[newDidName] = newNumberTypes[didName];
        }
      }
    }

    setNumberTypes(newNumberTypes);

    // Clear the last set of fields
    const lastNumKeys = Object.keys(allValues)
      .filter(key => key.startsWith(`user_${numUsers}_`));
    const clearLastFields = lastNumKeys.reduce((obj: { [key: string]: any }, key: string) => {
      obj[key] = undefined;
      return obj;
    }, {});
    delete newNumUsersDids[numUsers];
    form.setFieldsValue(clearLastFields);

    setNumUsers(numUsers - 1);
    setNumUsersDids(newNumUsersDids);
    // form.validateFields();
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Flex
      vertical
      gap={25}
      justify="center"
      align="center"
      style={{ width: "100%", height: "100%" }}
      className={styles.sign_up_container}
    >
      <Header step={3} />
      <div className={styles.sign_up_form_container}>
        <Form
          form={form}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className={styles.sign_up_form}
        >
          <Text className={styles.sign_up_form_section_title}>
            {t("subscribeStep3.organizationInfo")}
          </Text>
          <Form.Item
            name="companyName"
            label={t("fields.companyName")}
            rules={[
              {
                required: requiredFields.companyName,
                message: t("fields.companyNameRequired")
              },
            ]}
            className={
              `${styles.sign_up_form_item} ${requiredFields.companyName ? styles.required : ""}`
            }
          >
            <Input
              type="text"
              placeholder={t("fields.companyName")}
              className={styles.sign_up_form_input}
            />
          </Form.Item>
          <Form.Item
            name="timezone"
            label={t("fields.chooseTimezone")}
            rules={[
              {
                required: requiredFields.timezone,
                message: t("fields.chooseTimezoneRequired")
              }
            ]}
            className={
              `${styles.sign_up_form_item} ${requiredFields.timezone ? styles.required : ""}`
            }
          >
            <Select
              showSearch
              placeholder={t("fields.chooseTimezone")}
              options={timeZoneOptions}
              filterOption={selectFilterOption}
              className={`${styles.sign_up_form_input} ${styles.sign_up_form_select}`}
            />
          </Form.Item>
          <Form.Item
            name="country"
            label={t("fields.selectCountry")}
            initialValue={selectedCountry}
            rules={[
              {
                required: requiredFields.country,
                message: t("fields.selectCountryRequired")
              }
            ]}
            className={
              `${styles.sign_up_form_item} ${requiredFields.country ? styles.required : ""}`
            }
          >
            <Select
              placeholder={t("fields.selectCountry")}
              options={countryOptions}
              value={selectedCountry}
              onChange={onChangeCountry}
              className={`${styles.sign_up_form_input} ${styles.sign_up_form_select}`}
            />
          </Form.Item>
          {selectedCountry === "CA" ? (
            <Form.Item
              name="province"
              label={t("fields.selectProvince")}
              rules={[
                {
                  required: selectedCountry === "CA" && requiredFields.province,
                  message: t("fields.selectProvinceRequired")
                }
              ]}
              className={
                `${styles.sign_up_form_item} ${requiredFields.province ? styles.required : ""}`
              }
            >
              <Select
                showSearch
                placeholder={t("fields.selectProvince")}
                options={provinceOptions}
                filterOption={selectFilterOption}
                className={`${styles.sign_up_form_input} ${styles.sign_up_form_select}`}
              />
            </Form.Item>
          ) : (
            <Form.Item
              name="state"
              label={t("fields.selectState")}
              rules={[
                {
                  required: selectedCountry === "US" && requiredFields.state,
                  message: t("fields.selectStateRequired")
                }
              ]}
              className={
                `${styles.sign_up_form_item} ${requiredFields.state ? styles.required : ""}`
              }
            >
              <Select
                showSearch
                placeholder={t("fields.selectState")}
                options={stateOptions}
                filterOption={selectFilterOption}
                className={`${styles.sign_up_form_input} ${styles.sign_up_form_select}`}
              />
            </Form.Item>
          )}
          <Form.Item
            name="city"
            label={t("fields.selectCity")}
            rules={[
              {
                required: requiredFields.city,
                message: t("fields.selectCityRequired")
              }
            ]}
            className={
              `${styles.sign_up_form_item} ${requiredFields.city ? styles.required : ""}`
            }
          >
            <Input
              type="text"
              placeholder={t("fields.selectCity")}
              className={styles.sign_up_form_input}
            />
          </Form.Item>
          <Form.Item
            name="postalCode"
            label={selectedCountry === "CA"
              ? t("fields.postalCode")
              : t("fields.zipCode")}
            rules={[
              {
                required: requiredFields.postalCode,
                message: selectedCountry === "CA"
                  ? t("fields.postalCodeRequired")
                  : t("fields.zipCodeRequired")
              },
              { validator: validatePostalCode },
            ]}
            className={
              `${styles.sign_up_form_item} ${requiredFields.postalCode ? styles.required : ""}`
            }
          >
            <Input
              type="text"
              placeholder={selectedCountry === "CA"
                ? t("fields.postalCode")
                : t("fields.zipCode")}
              className={styles.sign_up_form_input}
            />
          </Form.Item>
          <Form.Item
            name="howDidYouHear"
            label={`${t("fields.howDidYouHear")} (${t("fields.optional")})`}
            className={
              `${styles.sign_up_form_item} ${requiredFields.howDidYouHear ? styles.required : ""}`
            }
            style={{ marginBottom: "20px" }}
          >
            <Input
              type="text"
              placeholder={t("fields.howDidYouHear")}
              className={styles.sign_up_form_input}
            />
          </Form.Item>

          {[...Array(numUsers)].map((_, i: number) => (
            <React.Fragment key={`user-${i + 1}`}>
              {i > 0 && numUsers > 1 && (
                <div style={{ justifyContent: "flex-end" }} className={styles.inline_btns}>
                  <span className={styles.inline_btn} onClick={() => deleteUser(i + 1)}>
                    {"- "}{t(
                      "subscribeStep3.deleteUser",
                      { number: capitalizeFirstLetter(formatNumberStringEN(i + 1)) }
                    )}
                  </span>
                </div>
              )}
              <CreateUserForm
                form={form}
                n={i + 1}
                name={
                  userData?.users
                  && userData.users.length > 0
                  && userData.users[0]?.firstName
                  && userData.users[0].lastName
                    ? `${userData.users[0].firstName} ${userData.users[0].lastName}`
                    : ""
                }
                email={userData?.users && userData?.users[i]?.email}
                numUsers={numUsers}
                numUsersDids={numUsersDids}
                setNumUsersDids={setNumUsersDids}
                numberTypes={numberTypes}
                setNumberTypes={setNumberTypes}
                initialLoad={initialLoad}
                isFreePlan={isFreePlan}
              />
            </React.Fragment>
          ))}
          {!isFreePlan && (
            <div className={styles.inline_btns}>
              <span className={styles.inline_btn} onClick={addUser}>
                {"+ "}{t("subscribeStep3.addAnotherUser")}
              </span>
            </div>
          )}

          <div className={styles.inline_btns}>
            <div className={`${styles.sign_up_form_item} ${styles.sign_up_form_btn}`}>
              <Button
                style={{ border: "1px solid #FAEBD5" }}
                className={styles.secondary}
                onClick={() => prevStep(0)}
              >
                {t("button.startOver")}
              </Button>
            </div>
            <div className={`${styles.sign_up_form_item} ${styles.sign_up_form_btn}`}>
              <Button
                type="primary"
                htmlType="submit"
                className={styles.primary}
              >
                {t("button.proceedNextStep")}
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </Flex>
  );
};

export default Step3;
