import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Checkbox, Form, Input, Select, SelectProps, Typography } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { PHONENUMBERREGEX, provinces, selectFilterOption, states } from "src/constants/common";
import { RequestedDidNumberType } from "src/constants/subscription";
import { capitalizeFirstLetter, getOptionsList } from "src/utils/helper-functions";
import { formatNumberStringEN } from "src/utils/format";
import styles from "src/pages/subscribe/subscribe.module.scss";

const { Text } = Typography;

interface ICreateNumberForm {
  form: any;
  i: number;
  j: number;
  isUserExpand: boolean;
  numDids: number;
  numberTypes: any;
  setNumberTypes: (numberType: any) => void;
  initialLoad: boolean;
}

const CreateNumberForm = ({
  form,
  i,
  j,
  isUserExpand = false,
  numDids,
  numberTypes,
  setNumberTypes,
  initialLoad,
}: ICreateNumberForm) => {
  const { t } = useTranslation();

  const [isExpand, setIsExpand] = useState<boolean>(true);
  const [selectedLocalCountry, setSelectedLocalCountry] = useState<string | undefined>();

  const didRequiredFields = {
    addNumber: true,
    specialRequests: false,
  };

  const countryOptions: SelectProps['options'] = [
    { key: "CA", label: t("geoLocation.countries.CA"), value: "CA" },
    { key: "US",label: t("geoLocation.countries.US"), value: "US" },
  ];
  const provinceOptions: SelectProps['options'] = getOptionsList(provinces);
  const stateOptions: SelectProps['options'] = getOptionsList(states);

  useEffect(() => {
    if (initialLoad) return;

    const formNumberType = numberTypes[`user_${i}_did_${j}_numberType`];

    if (formNumberType && formNumberType !== '') {
      if (formNumberType === RequestedDidNumberType.EXISTING) {
        form.resetFields([`user_${i}_did_${j}_specialRequests`]);
      } else {
        form.resetFields([`user_${i}_did_${j}_addNumber`]);
      }
    } else {
      let newNumberTypes = numberTypes;
      newNumberTypes = {
        ...newNumberTypes,
        [`user_${i}_did_${j}_numberType`]: RequestedDidNumberType.EXISTING,
      };
      setNumberTypes(newNumberTypes);
    }
  }, [initialLoad, numDids]);

  const resetFields = () => {
    let newNumberTypes = numberTypes;

    if (numberTypes[`user_${i}_did_${j}_numberType`] === RequestedDidNumberType.EXISTING) {
      form.resetFields([`user_${i}_did_${j}_addNumber`]);
      newNumberTypes = {
        ...newNumberTypes,
        [`user_${i}_did_${j}_numberType`]: RequestedDidNumberType.LOCAL,
      };
    } else {
      form.resetFields([`user_${i}_did_${j}_specialRequests`]);
      newNumberTypes = {
        ...newNumberTypes,
        [`user_${i}_did_${j}_numberType`]: RequestedDidNumberType.EXISTING,
      };
    }

    setNumberTypes(newNumberTypes);
  };

  const onChangeNumberType = () => {
    let newNumberTypes = numberTypes;
    const currNumberType = numberTypes[`user_${i}_did_${j}_numberType`];

    newNumberTypes = {
      ...newNumberTypes,
      [`user_${i}_did_${j}_numberType`]: currNumberType === RequestedDidNumberType.LOCAL
        ? RequestedDidNumberType.TOLLFREE
        : RequestedDidNumberType.LOCAL,
    };

    setNumberTypes(newNumberTypes);
  };

  const onUseRequestNumber = () => {
    let newNumberTypes = numberTypes;
    const currNumberType = numberTypes[`user_${i}_did_${j}_numberType`];

    newNumberTypes = {
      ...newNumberTypes,
      [`user_${i}_did_${j}_numberType`]:
        currNumberType === RequestedDidNumberType.REQUESTED
          ? RequestedDidNumberType.LOCAL
          : RequestedDidNumberType.REQUESTED,
    };

    setNumberTypes(newNumberTypes);
  };

  const onChangeCountry = (value: string) => {
    setSelectedLocalCountry(value);
    if (value === "CA") {
      form.resetFields([`user_${i}_did_${j}_local_state`]);
    } else if (value === "US") {
      form.resetFields([`user_${i}_did_${j}_local_province`]);
    }
    form.setFieldsValue({ [`user_${i}_did_${j}_local_country`]: value });
  };

  const validatePhoneNumber = () => {
    const number = form.getFieldValue(`user_${i}_did_${j}_addNumber`);
    const trimmedValue = number ? number.trim() : "";

    if (!trimmedValue || trimmedValue.length === 0) {
      return Promise.resolve();
    }

    if (!PHONENUMBERREGEX.test(trimmedValue)) {
      return Promise.reject(new Error(t("fields.addNumberRestriction")));
    }

    if (trimmedValue.length !== 10) {
      return Promise.reject(new Error(t("fields.addNumberLength")));
    }

    for (let k = 1; k <= numDids; k++) {
      if (k !== j) {
        const value = form.getFieldValue(`user_${i}_did_${k}_addNumber`);
        if (value === trimmedValue) {
          return Promise.reject(new Error(
            t(
              "fields.addNumberAlreadyInUse",
              { number: capitalizeFirstLetter(formatNumberStringEN(k)) }
            )
          ));
        }
      }
    }

    return Promise.resolve();
  };

  return (
    <div
      className={`${styles.sign_up_form_item} ${styles.sign_up_form} ${styles.sign_up_form_accordion_section} ${isUserExpand ? "" : styles.hidden}`}
    >
      <div
        style={{ position: "relative" }}
        className={styles.sign_up_form_item}
      >
        <Text className={styles.sign_up_form_section_title}>
          {t(
            "subscribeStep3.numberInfo",
            {
              userNumber: capitalizeFirstLetter(formatNumberStringEN(i)),
              numNumber: capitalizeFirstLetter(formatNumberStringEN(j)),
            }
          )}
        </Text>
        <Button
          className={styles.sign_up_form_accordion_btn}
          icon={
            isExpand ? ( <UpOutlined /> ) : ( <DownOutlined /> )
          }
          onClick={() => setIsExpand(!isExpand)}
        >
        </Button>
      </div>
      <div className={`${styles.sign_up_form_item} ${isExpand ? "" : styles.hidden}`}>
        <label>{t("fields.enablingExistingOrNewNumber")}</label>
        <div style={{ marginTop: "8px" }} className={styles.checkboxes}>
          <Checkbox
            checked={numberTypes[`user_${i}_did_${j}_numberType`] === RequestedDidNumberType.EXISTING}
            onChange={resetFields}
          >
            {t("fields.existingNumber")}
          </Checkbox>
          <Checkbox
            checked={numberTypes[`user_${i}_did_${j}_numberType`] !== RequestedDidNumberType.EXISTING}
            onChange={resetFields}
          >
            {t("fields.requestNewNumber")}
          </Checkbox>
        </div>
      </div>
      {numberTypes[`user_${i}_did_${j}_numberType`] === RequestedDidNumberType.EXISTING ? (
        <Form.Item
          name={`user_${i}_did_${j}_addNumber`}
          label={t("fields.addNumber")}
          className={
            `${styles.sign_up_form_item} ${didRequiredFields.addNumber ? styles.required : ""} ${isExpand ? "" : styles.hidden}`
          }
          rules={[
            {
              required: didRequiredFields.addNumber,
              message: t("fields.addNumberRequired")
            },
            { validator: () => validatePhoneNumber() },
          ]}
        >
          <Input
            placeholder={t("fields.addNumber")}
            className={styles.sign_up_form_input}
          />
        </Form.Item>
      ) : (
        <>
          {i > 1 && (
            <div className={`${styles.sign_up_form_item} ${isExpand ? "" : styles.hidden}`}>
              <label>{t("fields.useRequestedNumber")}</label>
              <div style={{ marginTop: "8px" }} className={styles.checkboxes}>
                <Checkbox
                  checked={numberTypes[`user_${i}_did_${j}_numberType`] === RequestedDidNumberType.REQUESTED}
                  onChange={onUseRequestNumber}
                >
                  {t("fields.yes")}
                </Checkbox>
                <Checkbox
                  checked={numberTypes[`user_${i}_did_${j}_numberType`] !== RequestedDidNumberType.REQUESTED}
                  onChange={onUseRequestNumber}
                >
                  {t("fields.no")}
                </Checkbox>
              </div>
            </div>
          )}
          {numberTypes[`user_${i}_did_${j}_numberType`] !== RequestedDidNumberType.REQUESTED && (
            <div className={`${styles.sign_up_form_item} ${isExpand ? "" : styles.hidden}`}>
              <label>{t("fields.typeOfNumber")}</label>
              <div style={{ marginTop: "8px" }} className={styles.checkboxes}>
                <Checkbox
                  checked={numberTypes[`user_${i}_did_${j}_numberType`] === RequestedDidNumberType.LOCAL}
                  onChange={onChangeNumberType}
                >
                  {t("fields.localNumber")}
                </Checkbox>
                <Checkbox
                  checked={numberTypes[`user_${i}_did_${j}_numberType`] === RequestedDidNumberType.TOLLFREE}
                  onChange={onChangeNumberType}
                >
                  {t("fields.tollFreeNumber")}
                </Checkbox>
              </div>
            </div>
          )}

          {numberTypes[`user_${i}_did_${j}_numberType`] === RequestedDidNumberType.LOCAL && (
            <div className={`${styles.sign_up_form_item} ${isExpand ? "" : styles.hidden}`}>
              <label>{t("fields.enterLocalInfo")}</label>
              <div style={{ marginTop: "8px" }} className={styles.dropdowns}>
                <Form.Item
                  name={`user_${i}_did_${j}_local_country`}
                  initialValue={selectedLocalCountry}
                  rules={[
                    {
                      required: numberTypes[`user_${i}_did_${j}_numberType`] === RequestedDidNumberType.LOCAL,
                      message: t("fields.selectCountryRequired")
                    }
                  ]}
                  className={
                    `${styles.sign_up_form_item} ${numberTypes[`user_${i}_did_${j}_numberType`] === RequestedDidNumberType.LOCAL ? styles.required : ""}`
                  }
                >
                  <Select
                    placeholder={t("fields.selectCountry")}
                    options={countryOptions}
                    value={selectedLocalCountry}
                    onChange={onChangeCountry}
                    className={`${styles.sign_up_form_input} ${styles.sign_up_form_select}`}
                  />
                </Form.Item>
                {selectedLocalCountry === "CA" ? (
                  <Form.Item
                    name={`user_${i}_did_${j}_local_province`}
                    rules={[
                      {
                        required: true,
                        message: t("fields.selectProvinceRequired")
                      }
                    ]}
                    className={`${styles.sign_up_form_item} ${styles.required}`}
                  >
                    <Select
                      showSearch
                      placeholder={t("fields.selectProvince")}
                      options={provinceOptions}
                      filterOption={selectFilterOption}
                      className={`${styles.sign_up_form_input} ${styles.sign_up_form_select}`}
                    />
                  </Form.Item>
                ) : (
                  <Form.Item
                    name={`user_${i}_did_${j}_local_state`}
                    rules={[
                      {
                        required: true,
                        message: t("fields.selectStateRequired")
                      }
                    ]}
                    className={`${styles.sign_up_form_item} ${styles.required}`}
                  >
                    <Select
                      showSearch
                      placeholder={t("fields.selectState")}
                      options={stateOptions}
                      filterOption={selectFilterOption}
                      className={`${styles.sign_up_form_input} ${styles.sign_up_form_select}`}
                    />
                  </Form.Item>
                )}
                <Form.Item
                  name={`user_${i}_did_${j}_local_city`}
                  rules={[
                    {
                      required: numberTypes[`user_${i}_did_${j}_numberType`] === RequestedDidNumberType.LOCAL,
                      message: t("fields.selectCityRequired")
                    }
                  ]}
                  className={
                    `${styles.sign_up_form_item} ${numberTypes[`user_${i}_did_${j}_numberType`] === RequestedDidNumberType.LOCAL ? styles.required : ""}`
                  }
                >
                  <Input
                    type="text"
                    placeholder={t("fields.selectCity")}
                    className={styles.sign_up_form_input}
                  />
                </Form.Item>
              </div>
            </div>
          )}
          <Form.Item
            name={`user_${i}_did_${j}_specialRequests`}
            label={t("fields.specialRequests")}
            className={`${styles.sign_up_form_item} ${isExpand ? "" : styles.hidden}`}
          >
            <Input
              placeholder={t("fields.specialRequests")}
              className={styles.sign_up_form_input}
            />
          </Form.Item>
        </>
      )}
    </div>
  );
};

export default CreateNumberForm;
