import { useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { STATUS } from "src/constants/status";
import { getDidLoading } from "src/store/selectors/features/dids";
import { getCurrentDidStatus } from "src/store/selectors/features/currentDid";
import DashboardFullNote from "src/components/dashboard-full-note";
import Contacts from "src/pages/contacts";
import Converstion from "src/pages/conversations";
import Plan from "src/pages/plan";
import PaymentMethod from "src/pages/plan/payment-method";
import KeyWords from "src/pages/settings/autoresponder/keywords";
import TimeOfDay from "src/pages/settings/autoresponder/time-of-day";
import Vacation from "src/pages/settings/autoresponder/vacation";
import Password from "src/pages/settings/password";
import Preferences from "src/pages/settings/preferences";
import Templates from "src/pages/settings/templates";

interface IDashboardRoutes {
  currentUserData: any;
}

const DashboardRoutes = ({
  currentUserData,
}: IDashboardRoutes) => {
  const didLoading = useSelector(getDidLoading);
  const didStatus = useSelector(getCurrentDidStatus);

  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isFullNoteOpen, setIsFullNoteOpen] = useState(false);

  const isPending = currentUserData?.isPending || (!didLoading && didStatus !== STATUS.ACTIVE);

  const getAllowedRoute = (element: any, isRestricted = false, hasFullNote = false) => {
    if (isRestricted) {
      return <Navigate to="/conversations" />;
    }

    if (isInitialLoad) {
      setIsInitialLoad(false);
      setIsFullNoteOpen(true);
    }

    return (
      <>
        {hasFullNote && (
          <DashboardFullNote
            isOpen={isFullNoteOpen}
            setIsOpen={setIsFullNoteOpen}
          />
        )}
        {element}
      </>
    );
  };

  return (
    <Routes>
      <Route
        path="/*"
        element={getAllowedRoute(<Converstion />, true, isPending)}
      ></Route>
      <Route
        path="/conversations"
        element={getAllowedRoute(<Converstion />, false, isPending)}
      ></Route>
      <Route
        path="/contacts"
        element={getAllowedRoute(<Contacts />, false, isPending)}
      ></Route>
      {/* <Route path="/group-contacts" element={<GroupContacts />}></Route>
      <Route path="/group-conversation" element={<GroupConverstion />}></Route> */}
      <Route
        path="/setting/password"
        element={getAllowedRoute(<Password />, false)}
      ></Route>
      <Route
        path="/setting/preferences"
        element={getAllowedRoute(<Preferences />, false)}
      ></Route>
      <Route
        path="/setting/templates"
        element={getAllowedRoute(
          <Templates />,
          isPending || currentUserData?.isInboundOnly
        )}
      ></Route>
      <Route
        path="/setting/time-of-day"
        element={getAllowedRoute(
          <TimeOfDay />,
          isPending || currentUserData?.isInboundOnly
        )}
      ></Route>
      <Route
        path="/setting/vacation"
        element={getAllowedRoute(
          <Vacation />,
          isPending || currentUserData?.isInboundOnly
        )}
      ></Route>
      <Route
        path="/setting/keywords"
        element={getAllowedRoute(
          <KeyWords />,
          isPending || currentUserData?.isInboundOnly
        )}
      ></Route>
      <Route
        path="/plan"
        element={getAllowedRoute(<Plan />, false)}
      ></Route>
      <Route
        path="/plan/payment-method"
        element={getAllowedRoute(<PaymentMethod />, false)}
      ></Route>
    </Routes>
  );
};

export default DashboardRoutes;
