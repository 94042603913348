import { createSelector } from "reselect";

const currentDidSelector = (state: TReduxState) => state.features.currentDid;

export const getCurrentDidId = createSelector(
  currentDidSelector,
  (currentDid) => currentDid.id
);

export const getCurrentDidStatus = createSelector(
  currentDidSelector,
  (currentDid) => currentDid.status
);
