import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Form, notification } from "antd";
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import RequestAppAction from "src/store/slices/appActions";
import Header from "../Header";
import styles from "src/pages/subscribe/subscribe.module.scss";
import { useForm } from "antd/es/form/Form";

interface IStep4 {
  prevStep: (n: number) => void;
  setPaymentIntentSecret: (paymentIntentSecret: string) => void;
  setStripe: (stripe: any) => void;
  setCardElement: (cardElement: any) => void;
  onFinish: () => void;
}

const Step4 = ({
  prevStep,
  setPaymentIntentSecret,
  setStripe,
  setCardElement,
  onFinish,
}: IStep4) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const [cardNumberCompleted, setCardNumberCompleted] = useState<boolean>(false);
  const [expDateCompleted, setExpDateCompleted] = useState<boolean>(false);
  const [cvcCompleted, setCvcCompleted] = useState<boolean>(false);
  const [disableButton, setDisableButton] = useState<boolean>(true);

  const requiredFields = {
    cardNumber: true,
    expirationDate: true,
    cvc: true,
  };

  useEffect(() => {
    onPaymentIntent();
  }, []);

  useEffect(() => {
    if (cardNumberCompleted && expDateCompleted && cvcCompleted) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [cardNumberCompleted, expDateCompleted, cvcCompleted]);

  const onPaymentIntent = () => {
    dispatch(RequestAppAction.setUpStripePaymentMethod({
      data: {},
      cbSuccess: (res: any) => {
        setPaymentIntentSecret(res.client_secret);
      },
      cbFailure: (e: string) => {
        notification.error({ message: e });
      },
    }));
  };

  const onCardNumberChange = (event: any) => {
    // Check if the form is valid
    if (event?.complete && event.complete === true && !event.error) {
      setCardNumberCompleted(true);
    } else {
      setCardNumberCompleted(false);
    }
  };

  const onExpDateChange = (event: any) => {
    // Check if the form is valid
    if (event?.complete && event.complete === true && !event.error) {
      setExpDateCompleted(true);
    } else {
      setExpDateCompleted(false);
    }
  };

  const onCvcChange = (event: any) => {
    // Check if the form is valid
    if (event?.complete && event.complete === true && !event.error) {
      setCvcCompleted(true);
    } else {
      setCvcCompleted(false);
    }
  };

  return (
    <>
      <Header step={4} />
      <div className={styles.sign_up_form_container}>
        <Form
          form={form}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          style={{ width: "100%" }}
          onFinish={() => {
            if (stripe && elements) {
              setStripe(stripe);
              setCardElement(elements.getElement(CardNumberElement));
            }
            onFinish();
          }}
          autoComplete="off"
          className={styles.sign_up_form}
        >

          <Form.Item
            name="cardNumber"
            label={t("fields.cardNumber")}
            rules={[
              {
                required: requiredFields.cardNumber,
                message: t("fields.cardNumberRequired")
              },
            ]}
            className={
              `${styles.sign_up_form_item} ${requiredFields.cardNumber ? styles.required : ""}`
            }
          >
            <CardNumberElement
              onChange={onCardNumberChange}
              className={`${styles.sign_up_form_input} ${styles.sign_up_form_input_stripe}`}
            />
          </Form.Item>
          <Form.Item
            name="expirationDate"
            label={t("fields.expiryDate")}
            rules={[
              {
                required: requiredFields.expirationDate,
                message: t("fields.expiryDateRequired")
              },
            ]}
            className={
              `${styles.sign_up_form_item} ${requiredFields.expirationDate ? styles.required : ""}`
            }
          >
            <CardExpiryElement
              onChange={onExpDateChange}
              className={`${styles.sign_up_form_input} ${styles.sign_up_form_input_stripe}`}
            />
          </Form.Item>
          <Form.Item
            name="cvc"
            label={t("fields.cvc")}
            rules={[
              {
                required: requiredFields.cvc,
                message: t("fields.cvcRequired")
              },
            ]}
            className={
              `${styles.sign_up_form_item} ${requiredFields.cvc ? styles.required : ""}`
            }
          >
            <CardCvcElement
              onChange={onCvcChange}
              className={`${styles.sign_up_form_input} ${styles.sign_up_form_input_stripe}`}
            />
          </Form.Item>

          <div className={styles.inline_btns}>
            <Form.Item className={`${styles.sign_up_form_item} ${styles.sign_up_form_btn}`}>
              <Button
                style={{ border: "1px solid #FAEBD5" }}
                className={styles.secondary}
                onClick={() => prevStep(3)}
              >
                {t("button.back")}
              </Button>
            </Form.Item>
            <Form.Item className={`${styles.sign_up_form_item} ${styles.sign_up_form_btn}`}>
              <Button htmlType="submit" className={styles.primary} disabled={disableButton}>
                {t("button.proceedNextStep")}
              </Button>
            </Form.Item>
          </div>

          <div className={`${styles.sign_up_form_note} ${styles.small}`}>
            {t("subscribeStep4.note")}
          </div>
        </Form>
      </div>
    </>
  );
};

export default Step4;
