import {
  Layout,
  notification,
  Row,
  Space,
  Spin,
  Typography,
} from "antd";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Colors from "src/themes/colors";
import { IMessages } from "src/constants/types";
import { LocalStorageService } from "src/services/local-storage";
import { getPreferenceData } from "src/store/selectors/features/preference";
import RequestAppAction from "src/store/slices/appActions";
import MessageBubble from "./messageBubble";

const { Content } = Layout;
const { Text } = Typography;

const localStorageService = new LocalStorageService();

interface IConversationMessages {
  conversationUser: any;
  messages: IMessages;
  scrollRef: any;
  isLoading: boolean;
}

const ConversationMessages = ({
  conversationUser,
  messages,
  scrollRef,
  isLoading,
}: IConversationMessages) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const preferencesData = useSelector(getPreferenceData);

  const [preferences, setPreferences] = useState<any>(null);

  useEffect(() => {
    if (
      preferencesData &&
      preferencesData?.timeZone !== preferences?.timeZone
    ) {
      setPreferences(preferencesData);
      localStorageService.persist(
        "preferences",
        JSON.stringify(preferencesData)
      );
    }
  }, [preferencesData]);

  useEffect(() => {
    localStorageService.fetch("preferences").then((res) => {
      let data = "";
      if (res) {
        data = JSON.parse(res);
      }

      if (preferencesData || data) {
        setPreferences(preferencesData ?? data);
        if (!data) {
          localStorageService.persist(
            "preferences",
            JSON.stringify(preferencesData)
          );
        }
      } else {
        dispatch(
          RequestAppAction.fetchPreference({
            cbSuccess: (res: any) => {
              if (res?.data) {
                setPreferences(res.data);
              }
            },
            cbFailure: (e: string) => {
              notification.error({
                message: e ?? t("notification.getPreferenceFail")
              });
            },
          })
        );
      }
    });
  }, []);

  return (
    <Content className="conversation-message">
      <Spin
        style={{ height: '100%', width: '100%' }}
        spinning={isLoading}
      >
        {scrollRef && messages && (
          <div ref={scrollRef} className="message-box pt-3">
            {messages.items?.map((message: any, key: number) => (
              <MessageBubble
                key={key}
                userId={message?.userId}
                username={message?.username}
                fileLocations={message?.fileLocations}
                content={message?.content}
                createdAt={message?.createdAt}
                timezone={preferences?.timeZone || "America/Toronto"}
              />
            ))}
          </div>
        )}

        {conversationUser?.isBlocked && (
          <Row style={{ background: Colors.Secondary }}>
            <Space className="w-100 d-flex align-items-center justify-content-center">
              <Text style={{ textAlign: "center", color: Colors.white }}>
                {t("common.userBlocked")}
              </Text>
            </Space>
          </Row>
        )}
      </Spin>
    </Content>
  );
};

export default ConversationMessages;
